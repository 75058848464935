import React, { Component } from 'react';
import './Documents.scss';
import TButton from '../TButton';
import Progress from 'react-progressbar';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import common from '../../common';


class Documents extends Component {
    constructor() {
        super();
        this.state = {
            selectedInvestmentDocuments: null,
            investmentData: null,
            iirCount: null,
            isExecutiveLetterProgress: null,
            investmentId: null,
            iirReviewApproveStatus: null,
            isApproveByStartup: false,
            execLetterReviewApproveStatus: null,
            onLoadInvestmentId: true,
            isApproveByStartupExl: false,
            executiveid: null,
        }
        this.onInvestmentChange = this.onInvestmentChange.bind(this);
    }

    getExecutiveLetter(investmentId) {
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvestmentIIRExecutiveLetter?investmentId=${investmentId}`, {
            headers: {
                "Authorization": tokenpass
            }
        }).then((response) => {
            if (response.data.data && response.data.data.execLetterReviewApproveStatus) {
                const result = response.data.data.execLetterReviewApproveStatus;
                if (result) {
                    this.setState({ execLetterReviewApproveStatus: result });
                    this.setState({ executiveid: response.data.data._id })
                }
            } else {
                this.setState({ execLetterReviewApproveStatus: null });
            }
        }).catch(function (error) {
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                window.localStorage.clear();
                window.location.href = '/';
            } else {
                swal({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    onInvestmentChange(event) {
        this.getExecutiveLetter(event.value);
        let temp = this;
        this.props.onInvestmentChange(true);
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/investmentData/${event.value}`, {
            headers: {
                "Authorization": tokenpass
            }
        }).then((response) => {
            temp.props.onInvestmentChange(false);
            this.setState({ investmentData: response.data.data });
            if (response.data.data && response.data.data.length > 0) {
                const result = response.data.data[0];
                if (result.iir) {
                    const iirObject = result.iir;
                    let isExecutiveLetterProgress = false;
                    if (iirObject['Founder Letter ']) {
                        isExecutiveLetterProgress = true;
                    }
                    delete iirObject['Founder Letter '];
                    const values = Object.values(iirObject);
                    let iirCount = 0;
                    iirCount = values.filter(iir => iir === true).length;
                    this.setState({ iirCount, isExecutiveLetterProgress, investmentId: event.value, iirReviewApproveStatus: response.data.data[0].iirReviewApproveStatus });
                }
            } else {
                this.setState({ iirCount: 0, isExecutiveLetterProgress: false, investmentId: event.value });
            }
        }).catch(function (error) {
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else if (error.response && error.response.data && error.response.data.code && error.response.data.code === 204) {
                window.localStorage.clear();
                window.location.href = '/';
            }
            swal({
                title: "Error!",
                text: error.message,
                icon: "error",
                button: "Ok!",
            });
            temp.props.onInvestmentChange(false);
        });
    }
    approveIir(investmentId) {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada2: true })
        axios.put(`${common.path.base_url}/isApprovedByStartup/${investmentId}`, {
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada2: false, isApproveByStartup: true })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada2: false });
            });
    }


    approveExl(investmentId) {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loadinglada2: true })
        axios.put(`${common.path.base_url}/isApprovedExecutiveLetterByStartup/${investmentId}/${this.state.executiveid}`, {
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                temp.setState({ loadinglada2: false, isApproveByStartupExl: true })
                swal({
                    title: "Updated!",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada2: false });
            });
    }

    render() {
        const props = this.props;
        const { profileData } = props;
        if (this.state.onLoadInvestmentId && props.selectedInvestmentDocuments && props.selectedInvestmentDocuments.value) {
            this.setState({ onLoadInvestmentId: false });
            this.getExecutiveLetter(props.selectedInvestmentDocuments.value);
        }
        return (
            <div>
                <div className="row profile-bottom-section Documents">
                    <div className="header-txt">{`Collaborative Documents for Edits and Approval`}</div>
                    <div className="startupInvestment">
                        <Select
                            id='startupInvestment'
                            options={this.props.startUpInvestments}
                            value={this.state.selectedInvestmentDocuments ? this.state.selectedInvestmentDocuments : this.props.selectedInvestmentDocuments}
                            onChange={(event) => {
                                this.setState({ selectedInvestmentDocuments: event })
                                this.onInvestmentChange(event);
                                // props.onInvestmentChange(event)
                            }
                            }
                        />
                    </div>
                    <div className='progressBarSection custom-progressbar'>
                        <div className='onBoardStepSection'>
                            <div className='onBoardStepSectionLabels'>
                                <div>
                                    {
                                        this.state.isExecutiveLetterProgress === null ?
                                            <a onClick={() => props.history.push(`/foundLetterPreview?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : this.props.companyId}&investmentId=${this.props.selectedInvestmentDocuments.value}`)} > Executive Letter </a>
                                            :
                                            <a onClick={() => props.history.push(`/foundLetterPreview?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : this.props.companyId}&investmentId=${this.state.investmentId}`)} > Executive Letter </a>
                                    }

                                </div>
                                <div>

                                    {
                                        this.state.isExecutiveLetterProgress === null ?
                                            props.executiveProgress ?
                                                `100% Completed` : `0% Completed` : ''
                                    }
                                    {
                                        this.state.isExecutiveLetterProgress !== null ?
                                            this.state.isExecutiveLetterProgress ?
                                                `100% Completed` : `0% Completed` : ''
                                    }
                                </div>
                            </div>
                            {
                                this.state.isExecutiveLetterProgress === null &&
                                <div className='progressBar'>
                                    <Progress className='subProgressBar'
                                        completed={props.executiveProgress ? 100 : 0}
                                    />
                                </div>
                            }
                            {
                                this.state.isExecutiveLetterProgress !== null &&
                                <div className='progressBar'>
                                    <Progress className='subProgressBar'
                                        completed={this.state.isExecutiveLetterProgress ? 100 : 0}
                                    />
                                </div>
                            }

                            {
                                this.state.investmentId ?
                                    this.state.execLetterReviewApproveStatus && this.state.execLetterReviewApproveStatus.isNotifiedByTSG &&
                                    <TButton
                                        id='edit-btn'
                                        onClick={() => !this.state.execLetterReviewApproveStatus.isApproveByStartup && this.approveExl(this.state.investmentId)}
                                        text={this.state.execLetterReviewApproveStatus.isApproveByStartup ? 'APPROVED' : 'APPROVE'}
                                        style={{ display: 'inline-block', width: '25%', 'margin-top': '8px' }}
                                        // disabled={this.state.execLetterReviewApproveStatus.isApproveByStartup}
                                        disabled={this.state.isApproveByStartupExl ? this.state.isApproveByStartupExl : this.state.execLetterReviewApproveStatus.isApproveByStartup}
                                    /> :
                                    props.selectedInvestmentDocuments && props.selectedInvestmentDocuments.value && this.state.execLetterReviewApproveStatus && this.state.execLetterReviewApproveStatus.isNotifiedByTSG &&
                                    <TButton
                                        id='edit-btn'
                                        onClick={() => this.approveExl(props.selectedInvestmentDocuments.value)}
                                        text={this.state.execLetterReviewApproveStatus.isApproveByStartup ? 'APPROVED' : 'APPROVE'}
                                        style={{ display: 'inline-block', width: '25%', 'margin-top': '8px' }}
                                        // disabled={this.state.execLetterReviewApproveStatus.isApproveByStartup}
                                        disabled={this.state.isApproveByStartupExl ? this.state.isApproveByStartupExl : this.state.execLetterReviewApproveStatus.isApproveByStartup}
                                    />
                            }

                        </div>
                        <div className='docUploadSection'>
                            <div className='docUploadSectionLabels'>
                                <div>
                                    {/* Document Upload Progress */}
                                    <a href="javascript:;" onClick={() => props.history.push(`/iir/company?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : this.props.companyId}&investmentId=${this.state.investmentId ? this.state.investmentId : props.selectedInvestmentDocuments.value}`)} > Investment Intelligence Report </a>
                                </div>
                                <div>
                                    {
                                        this.state.iirCount === null ?
                                            ((props.iirCount / 14) * 100).toFixed() > 100 ? '100% Completed' : `${((props.iirCount / 14) * 100).toFixed()}% Completed` :
                                            ((this.state.iirCount / 14) * 100).toFixed() > 100 ? '100% Completed' : `${((this.state.iirCount / 14) * 100).toFixed()}% Completed`
                                    }
                                </div>
                            </div>
                            <div className='progressBar'>
                                <Progress className='subProgressBar' completed={this.state.iirCount === null ? 7.2 * props.iirCount > 100 ? 100 : 7.2 * props.iirCount : 7.2 * this.state.iirCount > 100 ? 100 : 7.2 * this.state.iirCount} />
                            </div>

                        </div>
                        {
                            this.state.investmentId ?
                                this.state.iirReviewApproveStatus && this.state.iirReviewApproveStatus.isNotifiedByTSG &&
                                <TButton
                                    id='edit-btn'
                                    onClick={() => !this.state.iirReviewApproveStatus.isApproveByStartup && this.approveIir(this.state.investmentId)}
                                    text={this.state.iirReviewApproveStatus.isApproveByStartup ? 'APPROVED' : 'APPROVE'}
                                    style={{ display: 'inline-block', width: '25%' }}
                                    disabled={this.state.isApproveByStartup ? this.state.isApproveByStartup : this.state.iirReviewApproveStatus.isApproveByStartup}
                                /> :
                                props.selectedInvestmentDocuments && props.selectedInvestmentDocuments.value && props.selectedInvestmentDocuments.iirReviewApproveStatus
                                && props.selectedInvestmentDocuments.iirReviewApproveStatus.isNotifiedByTSG &&
                                <TButton
                                    id='edit-btn'
                                    onClick={() => this.approveIir(props.selectedInvestmentDocuments.value)}
                                    text={props.selectedInvestmentDocuments.iirReviewApproveStatus.isApproveByStartup ? 'APPROVED' : 'APPROVE'}
                                    style={{ display: 'inline-block', width: '25%' }}
                                    disabled={this.state.isApproveByStartup ? this.state.isApproveByStartup : props.selectedInvestmentDocuments.iirReviewApproveStatus.isApproveByStartup}
                                />
                        }

                    </div>
                    <div className="document-section">
                        <div className="document-header">
                            {`Documents`}
                        </div>
                    </div>
                    <div className="doc-btn-section">
                        <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="desc col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <TButton
                                    id='edit-btn'
                                    onClick={() => props.history.push('/faqPreview?faqPreview=true')}
                                    text={`STARTUP FAQ`}
                                    tsgBtnClass="tsg-blue-btn"
                                />
                            </div>
                        </div>

                        <div>
                            <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="desc">
                                    <TButton
                                        id='edit-btn'
                                        onClick={() => props.history.push(`/startUpEngagement?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : this.props.companyId}&investmentId=${this.state.investmentId ? this.state.investmentId : props.selectedInvestmentDocuments.value}`)}
                                        text={`ENGAGEMENT AGREEMENT`}
                                        tsgBtnClass="tsg-blue-btn"
                                    />
                                </div>
                            </div>
                            <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="desc">
                                    <TButton
                                        id='edit-btn'
                                        onClick={() => props.history.push(`/ndaPdf?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : this.props.companyId}&investmentId=${this.state.investmentId ? this.state.investmentId : props.selectedInvestmentDocuments.value}`)}
                                        text={`NDA`}
                                        tsgBtnClass="tsg-blue-btn"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// const Documents = (props) => {
//     const { profileData } = props;
//     console.log(props.startUpInvestments);
//     return (
//         <div>
//             <div className="row profile-bottom-section Documents">
//                     <div className="header-txt">{`Collaborative Documents for Edits and Approval`}</div>
//                     <div className="startupInvestment">
//                         <Select
//                             id='startupInvestment'
//                             options={props.startUpInvestments}
//                             value={props.selectedInvestment}
//                             onChange={(event) => {
//                                 props.onInvestmentChange(event)
//                             }
//                             }
//                         />
//                     </div>
//                     <div className='progressBarSection custom-progressbar'>
//                         <div className='onBoardStepSection'>
//                             <div className='onBoardStepSectionLabels'>
//                                 <div>
//                                     <a href="javascript:;" onClick={() => props.history.push(`foundLetterPreview?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : ''}`)} > Executive Letter </a>
//                                 </div>
//                                 <div>
//                                     {
//                                         props.executiveProgress &&
//                                         `100% Completed`
//                                     }
//                                     {
//                                         !props.executiveProgress &&
//                                         `0% Completed`
//                                     }
//                                 </div>
//                             </div>
//                             <div className='progressBar'>
//                                     <Progress className='subProgressBar' completed={props.executiveProgress ? 100 : 0} />
//                             </div>
//                     </div>
//                     <div className='docUploadSection'>
//                         <div className='docUploadSectionLabels'>
//                             <div>
//                                 {/* Document Upload Progress */}
//                                 <a href="javascript:;" onClick={() => props.history.push(`/preview?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : ''}`)} > Investment Intelligence Report </a>
//                             </div>
//                             <div>
//                                     {((props.iirCount/14)*100).toFixed()}% Completed
//                             </div>
//                         </div>
//                         <div className='progressBar'>
//                                 <Progress className='subProgressBar' completed={7.2*props.iirCount} />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="document-section">
//                     <div className="document-header">
//                         {`Document`}
//                     </div>
//                 </div>
//                 <div className="doc-btn-section">
//                     <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                         <div className="desc col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                             <TButton 
//                                 id='edit-btn' 
//                                 onClick={() => props.history.push('/faqPreview?faqPreview=true')} 
//                                 text={`STARTUP FAQ`} 
//                                 tsgBtnClass="tsg-blue-btn"
//                             />
//                         </div>
//                     </div>
//                     {props.userRoleCode === '003' && 
//                     <div>
//                         <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                             <div className="desc">
//                                 <TButton 
//                                     id='edit-btn' 
//                                     onClick={() => props.history.push(`/startUpEngagement?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : ''}`)} 
//                                     text={`ENGAGEMENT AGREEMENT`} 
//                                     tsgBtnClass="tsg-blue-btn"
//                                 />
//                             </div>
//                         </div>
//                         <div className=" profile-section-inner col-xs-12 col-sm-12 col-md-12 col-lg-12">
//                             <div className="desc">
//                                 <TButton 
//                                     id='edit-btn' 
//                                     onClick={() => props.history.push(`/ndaPdf?companyId=${profileData.companyId !== undefined ? profileData.companyId._id : ''}`)} 
//                                     text={`NDA`} 
//                                     tsgBtnClass="tsg-blue-btn"
//                                 />
//                             </div>
//                         </div>
//                     </div>}
//                 </div>
//             </div>
//         </div>
//     );
// }

export default Documents;