import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import swal from "sweetalert";
import common from "../../common";
import RequiredSign from "../../components/RequiredSign";
import images from "../../images";
import "./LaunchSCPRequestForm.scss";

class LaunchSCPRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      mobile: "",
      email: "",
      firstNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      isValid: false,
      selectedTotalPartners: { value: "None", label: "None" },
      totalChanelPartnersArray: [
        { value: "None", label: "None" },
        { value: "1-10", label: "1-10" },
        { value: "10-20", label: "10-20" },
        { value: "20+", label: "20+" },
      ],
      timeLine: null,
      key: 0,
    };
  }

  formValidaton(id, val) {
    if (id === "firstName") {
      let name = val;
      if (name === "") {
        this.setState({
          firstNameValidation: "First Name should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "lastName") {
      let name = val;
      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    } else if (id === "company") {
      let Company = val;
      if (Company === "") {
        this.setState({
          companyValidation: "Company name should not be empty*",
        });
      } else {
        this.setState({
          companyValidation: "",
        });
      }
    } else if (id === "title") {
      let title = val;
      if (title === "") {
        this.setState({
          titleValidation: "Title should not be empty*",
        });
      } else {
        this.setState({
          titleValidation: "",
        });
      }
    } else if (id === "email") {
      let email = val;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailValidation: "Email should not be empty*",
          isValid: false,
        });
      } else if (res !== true) {
        this.setState({
          emailValidation: "Please enter valid email*",
          isValid: false,
        });
      } else {
        this.setState({
          emailValidation: "",
        });
      }
    } else if (id === "website") {
      let website = val;
      if (website === "") {
        this.setState({
          websiteValidation: "Website should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          websiteValidation: "",
        });
      }
    } else if (id === "mobile") {
      let mobile = val;
      let mobileRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = mobileRegex.test(mobile);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(mobile);
        if (swissres !== true) {
          this.setState({
            mobileValidation: "Please enter valid mobile number",
            isValid: false,
          });
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    }
  }

  checkInvitaionFormValidity() {
    console.log(147, this.state.timeLine);
    if (
      !_.isEmpty(
        this.state.firstName &&
        this.state.lastName &&
        this.state.emailValidation === "" &&
        this.state.title &&
        this.state.company &&
        this.state.website &&
        this.state.mobile
      ) &&
      this.state.timeLine
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  }

  handleChange(id, newValue) {
    this.setState({ [id]: `+${newValue}` });
    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  }

  mapDateFormat(mongoDate) {
    if (!mongoDate) return null;
    const monthNames = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    return monthNames[monthIndex] + "/" + day + "/" + year;
  }

  sendSCPForm() {
    this.setState({ loadinglada: true });
    axios
      .post(
        `${common.path.base_url}/sendtSCPForm`,
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          company: this.state.company,
          title: this.state.title,
          website: this.state.website,
          email: this.state.email,
          mobile: this.state.mobile,
          timeLine: this.mapDateFormat(this.state.timeLine),
          totalChannelPartners: this.state.selectedTotalPartners.value,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ loadinglada: false });
        if (response && response.data) {
          this.resetForm();
          swal({
            title: "Request Sent!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          this.setState({ loadinglada: false });
          if (error.response) {
            swal({
              title: "Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }

  resetForm() {
    const key = this.state.key + 1;
    this.setState({
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      email: "",
      mobile: "",
      website: "",
      timeLine: null,

      firstNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      mobileValidation: "",
      websiteValidation: "",
      isValid: false,
      selectedTotalPartners: { value: "None", label: "None" },
      key,
    });
  }

  render() {
    return (
      <div>
        <div className="TSG-request-form-container" key={this.state.key}>
          <div className="login-container">
            <div className="LoginImage">
              <img
                src={images.path.login}
                alt=""
                style={{ width: "100%" }}
              ></img>
            </div>
            <div className="LoginCredentials">
              <div className="login">Launch Your Own SCP</div>
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Company Name <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="company"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Company"
                      value={this.state.company}
                    />
                    <p>{this.state.companyValidation}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    First Name <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <div>
                      <input
                        className="form-control"
                        id="firstName"
                        type="text"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        placeholder="First Name"
                        value={this.state.firstName}
                      />
                      <p>{this.state.firstNameValidation}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Last Name <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="lastName"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Last Name"
                      value={this.state.lastName}
                    />
                    <p>{this.state.lastNameValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Title <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="title"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Title"
                      value={this.state.title}
                    />
                    <p>{this.state.titleValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Email <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="email"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      type="text"
                      placeholder="Email Address"
                      value={this.state.email}
                    />
                    <p>{this.state.emailValidation}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Mobile <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8 mobile-num">
                    <ReactPhoneInput
                      country={"us"}
                      id="mobile"
                      onChange={(value) => this.handleChange("mobile", value)}
                      placeholder="Mobile"
                      disableAreaCodes={true}
                      countryCodeEditable={false}
                      autoComplete="none"
                      value={this.state.mobile}
                    />
                    <p>{this.state.mobileValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Website <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="website"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Website"
                      value={this.state.website}
                    />
                    <p>{this.state.websiteValidation}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Fundraising Timeline <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <DatePicker
                      selected={this.state.timeLine}
                      onChange={(date) => {
                        this.setState({ timeLine: date });
                        setTimeout(() => {
                          this.checkInvitaionFormValidity();
                        }, 100);
                      }}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    {" "}
                    Total Channel Partners :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <Select
                      id="numberOfPartners"
                      defaultValue={this.state.selectedTotalPartners}
                      options={this.state.totalChanelPartnersArray}
                      value={this.state.selectedTotalPartners}
                      onChange={(opt, action) => {
                        if (opt !== null && opt !== undefined) {
                          // this.handleChange("category", opt);
                          this.setState({ selectedTotalPartners: opt });
                        }
                      }}
                    />
                  </div>
                </div>
                <LaddaButton
                  loading={this.state.loadinglada}
                  onClick={() => this.sendSCPForm()}
                  className={!this.state.isValid ? "disable" : ""}
                  data-color="#eee"
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                >
                  Submit
                </LaddaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LaunchSCPRequestForm;
