import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTags from 'react-tag-autocomplete';
import swal from 'sweetalert';
import common from '../../common';
import TButton from '../../components/TButton';
import bell from '../../images/icons/bell.svg';
import chat from '../../images/icons/chat.svg';
import disbursement from '../../images/icons/disbursement.png';
import downarrow from '../../images/icons/downarrow.svg';
import exitright from '../../images/icons/exitright.svg';
import movetoescrow from '../../images/icons/movetoescrow.svg';
import search from '../../images/icons/search.svg';
import warning from '../../images/icons/warning.svg';
import AddressWireInfoModal from './AddressWireInfoModal';
import DetailCommunicationModel from './DetailCommunicationModal';
import IntialNotificationModal from './IntialNotificationModal';
import './StartupDetail.scss';

class ExitProcessInitialDisbursement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isFilterActive: false,
            tags: [],
            searchInputTags: [],
            searchText: null,
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
            addressWireInfo: {},
            confirmWire: {},
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }
    componentDidUpdate(prevProps) {
        if (this.props.inviteUsers !== prevProps.inviteUsers) {
            this.setState({ inviteUsers: this.props.inviteUsers, allInviteUsers: this.props.inviteUsers, pagination: this.props.pagination });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages && !this.state.isFilterActive)) {
        }
    };
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
    }
    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
        }
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessInitialDisbursementUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessInitialDisbursementUsers(false, this.mapTags())
        });
    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.getExitProcessInitialDisbursementUsers(selectedFilters, this.state.searchInputTags, this.mapTags(), $sort);
        this.setState({ arrowType, $sort });
    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    setDisbursementAmount = _.debounce((value, formattedValue, id) => {
        if (!value) {
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        const data = {
            disbursementAmount: value,
            formattedDisbursementAmount: formattedValue.slice(1),
            processId: id,
        }
        axios.post(`${common.path.base_url}/setInitialDisbursementAmount`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                //    temp.props.getExitProcessInitialDisbursementUsers(false);             
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);
    setEstimatedEscrowPayment = _.debounce((value, formattedValue, id) => {
        if (!value) {
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        const data = {
            estimatedEscrowPayment: value,
            formattedEstimatedEscrowPayment: formattedValue.slice(1),
            processId: id,
        }
        axios.post(`${common.path.base_url}/setEstimatedEscrowPaymentAmount`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {
                // temp.props.getExitProcessInitialDisbursementUsers(false);
            })
            .catch(function (error) {
                if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                else {
                    if (error.response) {
                        swal({
                            title: "Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                    else {
                        swal({
                            title: "Network Error!",
                            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                            icon: "error",
                            button: "Ok!",
                        });
                    }
                }
            });
    }, 800);
    setSharesDisbursed = _.debounce((value, id) => {
        if (!value) {
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        const data = {
            numberOfShareDisbursed: value,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setShareDisbursed`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                //    temp.props.getExitProcessInitialDisbursementUsers(false);             
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);
    setInitialDisbursementDate(date, id) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            disbursementDate: date,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setInitialDisbursementDate`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                this.props.getExitProcessInitialDisbursementUsers(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    setEscrowExpirationDate(date, id) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            escrowExpirationDate: date,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setEscrowExpirationDate`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                temp.props.getExitProcessInitialDisbursementUsers(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    movetoescrow(id) {
        let temp = this;
        let tokenpass = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        axios.post(`${common.path.base_url}/moveInitialDisbursementToEscrow/${id}`, {}, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then(function (response) {

            })
            .catch(function (error) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
                temp.setState({ loading: false, loadinglada2: false });
            });
    }

    createWalletHandler(user, amount) {
        let temp = this;
        let tokenpass = window.localStorage.getItem("token");
        temp.setState({ loading: true });
        temp.props.showLoading(true);
        const data = {
            orgName: `${user.user.name} Wallet`,
            emailAddress: user.emailId,
            userId: user.user._id,
        };
        axios
            .post(`${common.path.base_url}/createOrganization`, data, {
                headers: {
                    Authorization: tokenpass,
                },
            })
            .then((response) => {
                temp.setState({ loading: false });
                temp.props.showLoading(false);
                if (response.data) {
                    this.intialDisbursement(user, amount);
                }
            })
            .catch(function (error) {
                temp.props.showLoading(false);
                temp.setState({ loading: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    }).then(() => {
                        window.location.reload();
                    });
                } else {
                    swal({
                        title: "Network Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    };

    intialDisbursement(user, amount) {
        let temp = this;
        temp.setState({ loading: true });
        temp.props.showLoading(true);
        const token = window.localStorage.getItem('token');
        let data = {
            legalName: this.props.investmentData.legalName,
            investmentId: user.investmentId,
            userId: user.user._id,
            emailAddress: user.emailId,
            investmentProcessId: user._id,
            isEscrow: false,
            userName: user.name,
            investmentName: user.investmentName,
            disbursementAmount: Number(amount.replaceAll(",", "")),
        };
        axios.post(`${common.path.base_url}/exitDisbursement`, data, {
            headers: {
                Authorization: token,
            }
        }).then((response) => {
            temp.props.showLoading(false);
            temp.setState({ loading: false });
            this.movetoescrow(user._id);
            this.setInitialDisbursementDate(new Date(), user._id);
            swal({
                title: "Success",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
            }).then((resp) => {
            });
        }).catch((error) => {
            temp.props.showLoading(false);
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });

            }
            else {
                swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }
    render() {
        return (
            <div className="startup-box-wrapper-main margin-zero">
                <AddressWireInfoModal addressWireInfo={this.state.addressWireInfo}
                />
                {/* <ConfrimWireModal confirmWire={this.state.confirmWire}
                /> */}
                <IntialNotificationModal
                    onRef={(ref) => this.sendInitialNotificationChild = ref}
                    onSendInitialNotification={() => this.props.getExitInProcessUsers(false, this.mapTags())}

                />
                <DetailCommunicationModel
                    onRef={(ref) => this.sendDetailCommunicationChild = ref}
                    onSendDetailCommunication={() => this.props.getExitInProcessUsers(false, this.mapTags())}
                />
                <div className='header-investment exit-in-process'>
                    <div className="row searchpaddingtwopart">
                        <div className='search' style={{ width: '88%', position: 'relative' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)}
                            />
                            <img src={search} className="search-icon"></img>
                        </div>
                        <div className="all">
                            <div className="select-box">
                                <div className='status'>
                                    <select className='status-box' onChange={(event) => this.props.getExitProcessInitialDisbursementUsers(false, this.mapTags(), this.state.$sort, event.target.value)}>
                                        <option value="all">All Investment</option>
                                        {this.props.companyInvestmentsData && this.props.companyInvestmentsData.map((item) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.investmentData && this.props.investmentData.exitProcessType === 'acquisitionwithescrow' ?

                        <div className="custom-table-view table-responsive table-disbursement">
                            <table className="table table-custom sortable">
                                <thead>
                                    <tr>
                                        <th>INVESTOR <img src={downarrow} className="menu-icon"></img></th>
                                        <th>WALLET STATUS <img src={downarrow} className="menu-icon"></img></th>
                                        {/* {this.props.investmentData.exitProcessType === 'ipo' ?
                                            <th>BROKERAGE ACCOUNT </th>
                                            :
                                            <th>ADDRESS & WIRE INFO</th>
                                        } */}
                                        <th>COMPANY NAME <img src={downarrow} className="menu-icon"></img></th>
                                        <th>NET INVESTMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                        <th>INITIAL DISBURSEMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                        {/* <th>INITIAL DISBURSEMENT DATE <img src={downarrow} className="menu-icon"></img></th> */}
                                        {/* <th>CALCULATED DISBURSEMENT TOTALS<img src={downarrow} className="menu-icon"></img></th> */}
                                        {/* <th>ESCROW EXPIRATION <img src={downarrow} className="menu-icon"></img></th> */}
                                        <th>VIEW DOC.</th>
                                        <th>ACTION</th>
                                        {/* <th>CONFIRM WIRE</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.exitInProcessDisbursemenUsers.length > 0 &&
                                        this.props.exitInProcessDisbursemenUsers.map(user => {

                                            const calculatedDisbursementTotals = Number(user.disbursement1).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

                                            return (
                                                <tr key={user._id}>
                                                    <td>
                                                        {user.name}
                                                        <div className={user.isAcountVerified ? "tooltipcover" : "tooltipcover-notverified"}>
                                                            <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : 'Address not verified'}</span>
                                                            <img src={user.isAcountVerified ? exitright : warning} className="menu-icon"></img>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {user.user.verivendId ? "Created" : "Not Created"}
                                                    </td>

                                                    {/* {this.props.investmentData.exitProcessType === 'ipo' ?
                                                        <td>
                                                            {(user.brokerageAccount) ? user.brokerageAccount.WFAccountNumber : ""}
                                                        </td>
                                                        : <td><TButton onClick={(event) => this.setState({ addressWireInfo: user })} dataTarget={`#addressWireInfoModal`} dataToggle={`modal`} text="View" /> </td>

                                                    } */}
                                                    <td>
                                                        {user.companyName}
                                                    </td>
                                                    <td> <NumberFormat
                                                        value={user.amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />

                                                    </td>
                                                    <td>
                                                        <NumberFormat
                                                            value={calculatedDisbursementTotals}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'} />
                                                    </td>
                                                    <td>
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.subscriptionEnvId, 'Subscription')}>View QSA</a><br />
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.w9EnvId, this.getUserCitizenForm(user.isForeignCitizen))}>
                                                            {
                                                                user.isForeignCitizen ? 'View W-8' : 'View W-9'
                                                            }
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {/* <div className="tooltipcover-intial-notification">
                                                            <span className="tooltiptext">Intial notification</span>
                                                            <img  
                                                                alt="" 
                                                                src={bell} 
                                                                className="menu-icon"
                                                                data-target={`#intialNotificationModal`}
                                                                data-toggle={`modal`}
                                                                onClick={() => {
                                                                    this.setState(this.sendInitialNotificationChild.resetIntialNotificationData(), this.sendInitialNotificationChild.setData({
                                                                        user: user.user,
                                                                        companyName: this.props.investmentData.companyName,
                                                                        investmentId: user.investmentId,
                                                                        isFromMultipleSendMessage: false,
                                                                        investmentType: this.props.investmentData.exitProcessType,
                                                                        correspondingllcname: this.props.investmentData.llcName,
                                                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),

                                                                    }));
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="tooltipcover-communication">
                                                            <span className="tooltiptext">Communication</span>
                                                            <img alt=""
                                                                src={chat}
                                                                className="menu-icon"
                                                                data-target={`#detailCommunicationModal`}
                                                                data-toggle={`modal`}
                                                                onClick={() => {
                                                                    this.setState(this.sendDetailCommunicationChild.resetIntialNotificationData(), this.sendDetailCommunicationChild.setData({
                                                                        user: user.user,
                                                                        companyName: this.props.investmentData.companyName,
                                                                        investmentId: user.investmentId,
                                                                        isFromMultipleSendMessage: false,
                                                                        investmentType: this.props.investmentData.exitProcessType,
                                                                        correspondingllcname: this.props.investmentData.llcName,
                                                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                        initialpayoutdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                        escrowpercentageholdback: null,
                                                                        escrowexpirationdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                    }));
                                                                }}
                                                            />
                                                        </div> */}
                                                        <div className="tooltipcover-move-to-escrow">
                                                            <span className="tooltiptext">Disbursment</span>
                                                            <img alt="" 
                                                                src={movetoescrow}
                                                                className="menu-icon"
                                                                onClick={() => {
                                                                    if (user.user.verivendId) {
                                                                        this.intialDisbursement(user, calculatedDisbursementTotals);
                                                                    } else {
                                                                        swal({
                                                                            title: "Error!",
                                                                            text: "User haven't created a wallet yet. Please ",
                                                                            icon: "error",
                                                                            button: "Ok!",
                                                                        });
                                                                        // this.createWalletHandler(user, calculatedDisbursementTotals);
                                                                    }
                                                                }}
                                                            ></img>
                                                        </div>
                                                    </td>

                                                    {/* <td><TButton onClick={(event) => this.setState({ addressWireInfo: user })} dataTarget={`#addressWireInfoModal`} dataToggle={`modal`} text="View" /> </td> */}
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        this.props.exitInProcessDisbursemenUsers.length === 0 &&
                                        <tr>
                                            <td> No investors found for Exit In Process!</td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>

                        :
                        <div className="custom-table-view table-responsive">
                            <table className="table table-custom sortable">
                                <thead>
                                    <tr>
                                        <th>INVESTOR <img src={downarrow} className="menu-icon"></img></th>
                                        <th>WALLET STATUS <img src={downarrow} className="menu-icon"></img></th>
                                        {this.props.investmentData.exitProcessType === 'ipo' ?
                                            <th>BROKERAGE ACCOUNT </th>
                                            :
                                            <><th>ADDRESS & WIRE INFO</th></>
                                        }
                                        <th>COMPANY NAME <img src={downarrow} className="menu-icon"></img></th>
                                        <th>NET INVESTMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                        <th>$ VALUE OF DISPERSED SHARES<img src={downarrow} className="menu-icon"></img></th>
                                        {/* DISBURSEMENT AMOUNT */}
                                        {(this.props.investmentData.exitProcessType === 'acquisition' || this.props.investmentData.exitProcessType === 'acquisitionwithescrow') ? <th>DISBURSEMENT DATE <img src={downarrow} className="menu-icon"></img></th> : ''}
                                        <th>SHARES DISBURSED<img src={downarrow} className="menu-icon"></img></th>
                                        {this.props.investmentData.exitProcessType === 'ipo' ? <th>LOCKUP EXPIRATION <img src={downarrow} className="menu-icon"></img></th> : ''}
                                        <th>VIEW DOC.</th>
                                        <th>ACTION</th>
                                        <th>CONFIRM WIRE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.exitInProcessDisbursemenUsers.length > 0 &&
                                        this.props.exitInProcessDisbursemenUsers.map(user => {

                                            const calculatedDisbursementTotals = Number(user.disbursement1).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                                            return (
                                                <tr key={user._id}>
                                                    <td>
                                                        {user.name}
                                                        <div className="tooltipcover">
                                                            <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : 'Address not verified'}</span>
                                                            <img src={user.isAcountVerified ? exitright : warning} className="menu-icon"></img>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {user.user.verivendId ? "Created" : "Not Created"}
                                                    </td>

                                                    {this.props.investmentData.exitProcessType === 'ipo' ?
                                                        <td>
                                                            {(user.brokerageAccount) ? user.brokerageAccount.WFAccountNumber : ""}
                                                        </td>
                                                        :
                                                        <td><TButton onClick={(event) => this.setState({ addressWireInfo: user })} dataTarget={`#addressWireInfoModal`} dataToggle={`modal`} text="View" /> </td>
                                                    }

                                                    <td>
                                                        {user.companyName}
                                                    </td>
                                                    <td> <NumberFormat
                                                        value={user.amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />

                                                    </td>
                                                    <td>
                                                        <div className="form-group amount-investment">
                                                            <NumberFormat
                                                                value={calculatedDisbursementTotals}
                                                                thousandSeparator={true}
                                                                prefix={'$'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                onValueChange={(values) => {
                                                                    this.setDisbursementAmount(values.value, values.formattedValue, user._id);
                                                                }}
                                                            />
                                                        </div>

                                                    </td>
                                                    {(this.props.investmentData.exitProcessType === 'acquisition' || this.props.investmentData.exitProcessType === 'acquisitionwithescrow') ?
                                                        <td>
                                                            {this.mapDateFormat(this.props.investmentData.disbursementDate)}
                                                        </td> : ''}

                                                    <td>
                                                        <div className="form-group amount-investment">
                                                            <NumberFormat
                                                                value={user.numberOfShareDisbursed}
                                                                onValueChange={(values) => {
                                                                    this.setSharesDisbursed(values.floatValue, user._id);
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    {this.props.investmentData.exitProcessType === 'ipo' ? <td>
                                                        {this.mapDateFormat(this.props.investmentData.lockupDate)}
                                                    </td> : ''}

                                                    <td>
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.subscriptionEnvId, 'Subscription')}>View QSA</a><br />
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.w9EnvId, this.getUserCitizenForm(user.isForeignCitizen))}>
                                                            {
                                                                user.isForeignCitizen ? 'View W-8' : 'View W-9'
                                                            }
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="tooltipcover-intial-notification">
                                                            <span className="tooltiptext">Intial notification</span>
                                                            <img src={bell} className="menu-icon"
                                                                data-target={`#intialNotificationModal`}
                                                                data-toggle={`modal`}
                                                                onClick={() => {
                                                                    this.setState(this.sendInitialNotificationChild.resetIntialNotificationData(), this.sendInitialNotificationChild.setData({
                                                                        user: user.user,
                                                                        companyName: this.props.investmentData.companyName,
                                                                        investmentId: user.investmentId,
                                                                        isFromMultipleSendMessage: false,
                                                                        investmentType: this.props.investmentData.exitProcessType,
                                                                        correspondingllcname: this.props.investmentData.llcName,
                                                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                    }));
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="tooltipcover-communication">
                                                            <span className="tooltiptext">Communication</span>
                                                            <img
                                                                src={chat}
                                                                className="menu-icon"
                                                                data-target={`#detailCommunicationModal`}
                                                                data-toggle={`modal`}
                                                                onClick={() => {
                                                                    this.setState(this.sendDetailCommunicationChild.resetIntialNotificationData(), this.sendDetailCommunicationChild.setData({
                                                                        user: user.user,
                                                                        companyName: this.props.investmentData.companyName,
                                                                        investmentId: user.investmentId,
                                                                        isFromMultipleSendMessage: false,
                                                                        investmentType: this.props.investmentData.exitProcessType,
                                                                        correspondingllcname: this.props.investmentData.llcName,
                                                                        acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                        ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                        initialpayoutdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                        escrowpercentageholdback: null,
                                                                        escrowexpirationdate: this.mapDateFormat(this.props.investmentData.disbursementDate),

                                                                    }));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="tooltipcover-communication">
                                                            <span className="tooltiptext">Disbursement</span>
                                                            <img
                                                                src={disbursement}
                                                                alt=""
                                                                className="menu-icon"
                                                                style={{ width: "40%", }}
                                                                onClick={() => {
                                                                    if (user.user.verivendId) {
                                                                        this.intialDisbursement(user, calculatedDisbursementTotals);
                                                                    } else {
                                                                        this.createWalletHandler(user, calculatedDisbursementTotals);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{(user.wireReferenceNo === undefined || user.wireReferenceNo === '') ? <TButton onClick={(event) => this.setState({ confirmWire: user })} dataTarget={`#confirmWireModal`} dataToggle={`modal`} text="Add" /> : <>
                                                        Ref #: {user.wireReferenceNo}<br />Date: {this.mapDateFormat(user.wireDate)}</>} </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        this.props.exitInProcessDisbursemenUsers.length === 0 &&
                                        <tr>
                                            <td> No investors found for Exit In Process!</td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        );
    }

}

export default ExitProcessInitialDisbursement;