import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import LaddaButton, { XS, EXPAND_RIGHT } from 'react-ladda';
import common from '../../../common';
import LoadingSpinner1 from '../../../components/IIR Loader';
import { path } from '../../imagesPath';

class ReSignDocModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: null,
            processId: '',
            invitedInvestments: null,
            loading: false,
            isEntity: true,
            phoneNumber: '',
            companyName: '',
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    getFormData(userId, investmentId, callback) {
        let temp = this;
        let token = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getUserInvestmentProcessById/${userId}/${investmentId}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                let responseData = response.data.data;
                temp.setState({ processId: response.data.data.investmentprocesses[0]._id });
                if (responseData && responseData.investmentCompanies && responseData.investmentCompanies.length > 0) {
                    temp.setState({ companyName: responseData.investmentCompanies[0].companyName });
                }
                if (responseData && responseData.invitedInvestments && responseData.invitedInvestments.length > 0) {
                    temp.setState({ invitedInvestments: responseData.invitedInvestments[0] });
                }
                temp.setState({ phoneNumber: responseData.mobile });

                if (responseData.investmentprocesses[0] && responseData.investmentprocesses[0].taxIdNumber && responseData.investmentprocesses[0].taxIdNumber.includes('-')) {
                    responseData.investmentprocesses[0].taxIdNumber = responseData.investmentprocesses[0].taxIdNumber.replaceAll('-', '');
                }

                const investment = (responseData.invitedInvestments[0]) ? responseData.invitedInvestments[0] : null;
                const pps = (investment != null) ? investment.pricePerShare : null;

                temp.setFinaldata(responseData.investmentprocesses[0], pps);
                callback(true);
            })
            .catch(function (error) {
                callback(false);
            });
    }

    setFinaldata(investmentDetail, defaultPPS) {
        
        this.setState({
            documents: {
                [investmentDetail._id]: {
                    amount: investmentDetail.amount,
                    formattedAmount: investmentDetail.formattedAmount,
                    type: investmentDetail.type ? investmentDetail.type : 'entity',
                    firstName: investmentDetail.firstName ? investmentDetail.firstName : '',
                    middleName: investmentDetail.middleName ? investmentDetail.middleName : '',
                    lastName: investmentDetail.lastName ? investmentDetail.lastName : '',
                    entityName: investmentDetail.entityName ? investmentDetail.entityName : '',
                    otherEntityType: investmentDetail.otherEntityType ? investmentDetail.otherEntityType : '',
                    title: investmentDetail.title ? investmentDetail.title : '',
                    address1: investmentDetail.address1,
                    address2: investmentDetail.address2,
                    city: investmentDetail.city,
                    state: investmentDetail.state,
                    zipcode: investmentDetail.zipcode,
                    memberType: investmentDetail.memberType,
                    taxIdNumber: investmentDetail.taxIdNumber && investmentDetail.taxIdNumber.toString() !== 'false' ? investmentDetail.taxIdNumber : '',
                    taxIdNumberType: investmentDetail.taxIdNumberType ? investmentDetail.taxIdNumberType : '',
                    isForeignCitizen: investmentDetail.isForeignCitizen === true,
                    country: investmentDetail.country,
                    foreignTaxId: investmentDetail.foreignTaxId,
                    isQSAresend: investmentDetail.isQSAResend,
                    isW8resend: investmentDetail.isW8Resend,
                    isW9resend: investmentDetail.isW9Resend,
                    isSignSideLetterResend: investmentDetail.isSignSideLetterResend,
                    pricePerShare : ( investmentDetail.pricePerShare > 0 ) ? investmentDetail.pricePerShare : null,
                    defaultPPS : ( defaultPPS > 0 ) ? defaultPPS : null,
                    pricePerClass : ( (investmentDetail.pricePerShare > 0) && ( defaultPPS > 0 ) ) ? Number( investmentDetail.pricePerShare / defaultPPS ) : 1,
                }
            }
        });
    }


    // Function calls for form data --*
    getQSAPowerFormId(type, shareType, entityType) {
        let powerFormID = null;
        if (shareType === 'Common') {
            shareType = 'COMMON';
        } else {
            shareType = 'PREFEERED';
        }
        if (type === 'individual') {
            powerFormID = process.env[`REACT_APP_${shareType}_INDIVIDUAL_ID`];
        } else if (type === 'entity' && entityType && entityType.includes('Trust')) {
            powerFormID = process.env[`REACT_APP_${shareType}_ENTITY_TRUST_ID`];
        } else {
            powerFormID = process.env[`REACT_APP_${shareType}_ENTITY_ID`];
        }

        return powerFormID;
    }
    getCitizenPowerFormId(isForeignCitizen, type) {
        let powerFormID = null;
        if (isForeignCitizen) {
            if (type === 'entity') {
                powerFormID = process.env[`REACT_APP_W8_ENTITY_ID`];
            }
            else {
                powerFormID = process.env[`REACT_APP_W8_ID`];
            }

        } else {
            powerFormID = process.env[`REACT_APP_W9_ID`];
        }

        return powerFormID;
    }
    getSignSideLettterPowerFormId() {
        let powerFormID = process.env.REACT_APP_SIDE_LETTER_ID;
        return powerFormID;
    }
    getUserCarry(carry, type) {
        let carryVal = carry;
        if(type == 2) {
          carryVal = 100 - carry;
        } 
        carryVal = carryVal;
        return carryVal;
    }
    getUserName(id) {
        let name = ''
        const documents = { ...this.state.documents };
        const document = documents[id];
        name = document.firstName;
        if (document.middleName) {
            name += ' ' + document.middleName;
        }
        if (document.lastName) {
            name += ' ' + document.lastName;
        }
        return name;
    }
    addressFormat(id) {
        let fullAddress = ''
        const documents = { ...this.state.documents };
        const document = documents[id];
        fullAddress = document.address1;
        if (document.address2) {
            fullAddress += ', ' + document.address2;
        }
        fullAddress += ', ' + document.city;
        fullAddress += ', ' + document.state;
        fullAddress += ', ' + document.zipcode;
        fullAddress += ', ' + document.country;

        return fullAddress;
    }
    w9FullAddress(id) {
        let fullAddress = ''
        const documents = { ...this.state.documents };
        const document = documents[id];
        fullAddress = document.address1;
        if (document.address2) {
            fullAddress += ', ' + document.address2;
        }
        return fullAddress;
    }
    formatTaxIdNumber(number) {
        if (number) {
            let array = number.split('');
            array.splice(2, 0, '-');
            return array.join('');
        }
        return number;

    }
    formatSSN(number) {
        if (number) {
            let array = number.split('');
            array.splice(3, 0, '-');
            array.splice(6, 0, '-');
            return array.join('');
        }
        return number;

    }
    resetInvestmentData() {
        this.setState({
            documents: null,
            processId: '',
            invitedInvestments: null,
            loading: false,
            isEntity: true,
            phoneNumber: '',
            companyName: '',
        });
    }
    render() {
        if (!this.state.invitedInvestments || !this.state.documents) {
            return null;
        }
        const investment = this.state.invitedInvestments;
        const investmentProcess = investment.investmentprocesses && investment.investmentprocesses[0] && investment.investmentprocesses[0];
        const processId = investmentProcess && investmentProcess._id;
        const llcName = investment.llcName;
        const shareType = investment.shareType;
        return (
            <div id="reSignDocModal" className="modal fade" data-backdrop="static" data-keyboard="false">
                {this.state.loading ? <LoadingSpinner1 /> : ""}
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Sign Document</h4>
                            <img src={path.closeButtonIcon} type="button" id="reSend-btn-cancel" data-dismiss="modal" onClick={() => this.resetInvestmentData()} />
                        </div>
                        <div className="modal-body">
                            <h4 className="modal-body-text">TSG has requested that you re-sign the following documents:</h4>
                            {/* <h4 className="modal-body-text">documents below:</h4> */}
                        </div>
                        <div className="resend-btn-content">
                            <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getQSAPowerFormId(this.state.documents[processId].type, shareType, this.state.documents[processId].memberType)}`} method="POST">
                                <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                <input type="hidden" name="v" id="v" value='2' />
                                <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={true} />
                                <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='subscription' />
                                <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                <input type="hidden" name="investor_amount" id="investor_amount" value={this.state.documents[processId].formattedAmount} />
                                <input type="hidden" name="investor_pricePerClass" id="investor_pricePerClass" value={ Number(this.state.documents[processId].pricePerClass).toFixed(4) } />
                                <input type="hidden" name="investor_classAUnits" id="investor_classAUnits" value={ Number( Number(this.state.documents[processId].amount) / Number(this.state.documents[processId].pricePerClass).toFixed(4) ).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})  } />
                                <input type="hidden" name="investor_contactPerson" id="investor_contactPerson" value={this.getUserName(processId)} />
                                <input type="hidden" name="investor_investorEmail" id="investor_investorEmail" value={window.localStorage.getItem('emailAddress')} />
                                <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                <input type="hidden" name="investor_address" id="investor_address" value={this.addressFormat(processId)} />
                                <input type="hidden" name="investor_state" id="investor_state" value={this.state.documents[processId].state} />
                                <input type="hidden" name="investor_phoneNumber" id="investor_phoneNumber" value={this.state.phoneNumber} />
                                <input type="hidden" name="investor_LLCName" id="investor_LLCName" value={llcName} />
                                <input type="hidden" name="investor_companyName" id="investor_companyName" value={this.state.companyName} />
                                <input type="hidden" name="investor_title" id="investor_title" value={this.state.documents[processId].title} />
                                <input type="hidden" name="investor_entityName" id="investor_entityName" value={this.state.documents[processId].entityName} />
                                <input type="hidden" name="investor_memberType" id="investor_memberType" value={this.state.documents[processId].memberType ? this.state.documents[processId].memberType : 'Corporation'} />
                                <input type="hidden" name="investor_otherEntityType" id="investor_otherEntityType" value={this.state.documents[processId].otherEntityType ? this.state.documents[processId].otherEntityType : ''} />
                                {
                                    this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                        <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value="yes" /> :
                                        <input type="hidden" name="EnvelopeField_isForeignCitizen" id="investor_isForeignCitizen" value="no" />
                                }
                                {this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                    <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                    :
                                    <>
                                        {
                                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ?
                                                this.state.documents[processId].taxIdNumberType === 'SSN' ?
                                                    <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />
                                                    :
                                                    <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatTaxIdNumber(this.state.documents[processId].taxIdNumber)} />
                                                :
                                                <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />
                                        }</>
                                }
                                {this.state.documents[processId].isQSAresend &&
                                    <button type='submit' className='btn btn-danger'>Re-Sign QSA</button>
                                }
                            </form>
                            <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getCitizenPowerFormId(this.state.documents[processId].isForeignCitizen, this.state.documents[processId].type)}`} method="POST">
                                <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={true} />
                                <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                <input type="hidden" name="investor_name" id="investor_name" value={this.getUserName(processId)} />
                                <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                <input type="hidden" name="investor_address" id="investor_address" value={this.w9FullAddress(processId)} />
                                <input type="hidden" name="investor_cityStateZip" id="investor_cityStateZip" value={`${this.state.documents[processId].city}, ${this.state.documents[processId].state}, ${this.state.documents[processId].zipcode}`} />
                                <input type="hidden" name="investor_country" id="investor_country" value={this.state.documents[processId].country} />
                                <input type="hidden" name="investor_foreignTaxIdNumber" id="investor_foreignTaxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                {
                                    this.state.documents[processId].type === 'entity' &&
                                    <input type="hidden" name="investor_entityName" id="investor_entityName" value={this.state.documents[processId].entityName} />
                                }
                                {
                                    this.state.documents[processId].type === 'individual' &&
                                    <input type="hidden" name="investor_w9MemberType" id="investor_w9MemberType" value='Individual' />
                                }
                                {
                                    this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ?

                                        this.state.documents[processId].taxIdNumberType === 'SSN' ?
                                            <input type="hidden" name="investor_SSN" id="investor_SSN" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} /> :
                                            <input type="hidden" name="investor_EIN" id="investor_EIN" value={this.formatTaxIdNumber(this.state.documents[processId].taxIdNumber)} /> :
                                        <input type="hidden" name="investor_SSN" id="investor_SSN" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />


                                }
                                <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                <input type="hidden" name="v" id="v" value='2' />
                                <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='signw9' />
                                {
                                    this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                        <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value="yes" /> :
                                        <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value="no" />
                                }
                                {
                                    (this.state.documents[processId].isW8resend || this.state.documents[processId].isW9resend) &&
                                    (<button type='submit' className='btn btn-danger'>{this.state.documents[processId].isForeignCitizen && this.state.documents[processId].isW8resend ? 'Re-Sign W8' : this.state.documents[processId].isW9resend && 'Re-Sign W9'}</button>)
                                }
                            </form>
                            
                            <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                                <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                <input type="hidden" name="v" id="v" value='2' />
                                <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                                <input type="hidden" name="investor_entityName" id="investor_entityName" value={ investment.llcName } />
                                <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                                <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                                <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1) } />
                                <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee } />
                                <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                                <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                                <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />

                                {
                                ( this.state.documents[processId].isSignSideLetterResend ) &&
                                (<button type='submit' className='btn btn-danger'>Re-Sign Side Letter</button>)
                                }    
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReSignDocModal;