import React from 'react';
import DetailView from '../../components/DetailView/DetailView';
import './Start.scss';

const redirect = () => {
    const url = new URL(window.location.href);
    const investorId = url.searchParams.get('investorId');
    if (investorId) {
        window.location.href = `/investor-dashboard?investorId=${investorId}`
    } else {
        window.location.href = `/investor-dashboard`;
    }
}


const DashboardStart = (props) => (
    <>
    <div className='back-dashboard' onClick={()=>redirect(props)}>
      <button className='btn btn-danger' >Back to Dashboard</button>
    </div>
    <div className="new_investment">
        <div className="container-fluid">
            <h2>Member Wallet Details</h2>
        </div>
    </div>
    <div className="invitations_sec new_investment">
        <div className="container-fluid">
            <div className="row">
                <DetailView />
            </div>
        </div>
    </div>
    </>
)


export default DashboardStart