const FormType = {
    wireAccountNumber: "wireAccountNumber",
    wireAccountName: "wireAccountName",
    wireRoutingNumber: "wireRoutingNumber",
    achNickname: "achNickname",
    achAccountNumber: "achAccountNumber",
    achAccountName: "achAccountName",
    achRoutingNumber: "achRoutingNumber",
}

const path = {
    base_url: process.env.REACT_APP_API_DOMAIN,
    base_url2: process.env.REACT_APP_HOST_DOMAIN,
    doc_download_url: process.env.REACT_APP_DOWNLOAD_URL,
    "001": "/dashboard",
    "002": "/dashboard-TSGManager",
    "003": "/dashboard-StartupAdmin",
    "004": "/dashboard-StartupManager",
    "005": "/dashboard-investor"
};

const editorConfig = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
        '|',
        'alignment',
        'fontColor',
        'fontSize',
        'highlight',
        'removeFormat',
        'underline',
    ],
    ckfinder: {
        uploadUrl: '/ckfinder/connector?command=QuickUpload&type=Files&responseType=json',
    },
    image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:side', 'linkImage'],
    },
}

const mapKeys = (key) => {
    if (key === 'Major Investor') {
        return 'Key Investors';
    } else if (key === 'Product Statement') {
        return 'Problem Statement';
    } else if (key === 'Company') {
        return 'Company Overview';
    } else if (key === 'Market Performance and Summary') {
        return 'Market Overview';
    } else if (key === 'Funding') {
        return 'Funding Information / History';
    } else {
        return key;
    }

}

const iirMapKey = ({ key, values, i }) => {
    // console.log(31, key, i, values);
    return {
        title: mapKeys(key),
        state: values[i],
        path: key
    }
}

const transactionStatus = {
    failed: "FAILED",
    success: "COMPLETED",
    started: "SCHEDULED",
}

const allowedRolesToModifyCompany = ['001', '002', '005'];

const iirArrayLength = 14;
const paginationLimit = 200;
const inviteSendLimit = 500;
const AUTH_EXPIRED = 'Auth is expired or not sent.';

const investmentStatuses = {
    interested: 'Interested',
    notInterested: 'Not Interested',
    executed: 'Executed',
    complete: 'Complete',
    cancelled: 'Cancelled',
    participate: 'Participate',
    invited: 'Invited',
};

const checklistTypes = {
    open: 'Open',
    close: 'Close',
    exit: 'Exit',
    all: 'All',
};

const companyInvestmentStatus = {
    exitInProcess: 'exit in process',
    exited: 'exited',
    inProcess: 'in process',
}

const orderMap = {
    "Investment Overview": 1,
    "Company Overview": 2,
    "Market Overview": 3,
    "Leadership": 4,
    "Board": 5,
    "Problem Statement": 6,
    "Solution Summary": 7,
    "Product": 8,
    "Differentiation": 9,
    "Key Investors": 10,
    "Funding Information / History": 11,
    "Historical and Projected Revenue": 12,
    "Key Customers": 13,
    "Current Partners": 14,
};

export default {
    path,
    editorConfig,
    iirMapKey,
    iirArrayLength,
    orderMap,
    allowedRolesToModifyCompany,
    paginationLimit,
    investmentStatuses,
    FormType,
    checklistTypes,
    inviteSendLimit,
    AUTH_EXPIRED,
    companyInvestmentStatus,
    transactionStatus,
}