import React, { Component } from "react";
import "react-phone-input-2/lib/style.css";
import { withRouter } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import downarrow from "../../images/icons/downarrow.svg";
import "./viewProfile.scss";
import Header from "../../components/Header";
import LoadingSpinner from "../../components/Loader";
import common from "../../common";
import NumberFormat from "react-number-format";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      name: "",
      firstName: "",
      firstNamePreferred: "",
      middleName: "",
      lastName: "",
      title: "",
      company: "",
      emailAddress: "",
      mobile: "",
      office: "",
      addedOn: null,
      addedBy: null,
      fullAddress: null,
      loading: false,
      Role: "",
      roleCode: "",
      nameValidation: "",
      firstNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailAddressValidation: "",
      mobileValidation: "",
      officeValidation: "",
      profileFormValidated: true,
      inputFile: null,
      notes: "",
      startupCompanyTags: [],
      startupInvestmentTags: [],
      investorReferralTags: [],
      startupCompaniesList: [],
      selectedStartupCompanyOption: "",
      investorReferralList: [],
      selectedReferralOption: "",
      selectedcategory: "",
      category: [
        { value: "Personal", label: "Personal" },
        { value: "YPO", label: "YPO" },
        { value: "Institutional", label: "Institutional" },
        { value: "Strategic", label: "Strategic" },
        { value: "Unsubscribed", label: "Unsubscribed" },
        { value: "Blacklist", label: "Blacklist" },
        { value: "Preferred", label: "Preferred" },
        { value: "Investment(s)", label: "Investment(s)" },
        { value: "Spire One", label: "Spire One" },
        { value: "International", label: "International" },
        { value: "Federal", label: "Federal" },
        { value: "CXO", label: "CXO" },
      ],
      selectedCategoryTags: [],
      enterpassValidation: "",
      confirmpassValidation: "",
      pass1: "",
      pass2: "",
      isModalValid: false,
      userRole: null,
      arrowType: {
        companyName: "menu-icon",
        amount: "menu-icon",
        investmentName: "menu-icon",
        fundedDate: "menu-icon",
        stage: "menu-icon",
        returnAmount: "menu-icon",
        profit: "menu-icon",
      },
      userInvestmentHistory: [],
      totalInvestment: null,
      totalInvestmentAmount: null,
      addedByTags: [],
    };
    const url = new URL(window.location.href);
    this.userId = url.searchParams.get("id");
    this.goback = this.goback.bind(this);
  }

  componentWillMount() {
    window.addEventListener("popstate", (event) => {
      const url = new URL(window.location.href);
      if (url && url.searchParams.get("tab")) {
        window.location.href = "pipeline-startups?tab=recommendation";
      }
    });

    this.getData();
    const userRole = window.localStorage.getItem("role");
    this.setState({ Role: userRole });
    const userRoleCode = window.localStorage.getItem("roleCode");
    this.setState({ roleCode: userRoleCode });
    this.getUserInvestmentHistory(false, this.mapTags());
  }
  goback(event) {
    event.preventDefault();
    this.props.history.goBack();
  }
  getData() {
    if (this.state.inputFile) {
      this.setProfileImage();
    }
    let temp = this;
    temp.setState({ loading: true });
    let id = window.localStorage.getItem("id");
    if (this.userId) {
      id = this.userId;
    }
    this.setState({ id: id });
    axios
      .get(`${common.path.base_url}/getUserById`, {
        params: {
          _id: id,
        },
      })
      .then((response) => {
        // const splitName = response.data.data.name.split(' ');
        temp.setState({ loading: false });
        // this.setState({ name: response.data.data.name });
        this.setState({ firstName: response.data.data.firstName });
        this.setState({
          firstNamePreferred: response.data.data.firstNamePreferred,
        });
        this.setState({ lastName: response.data.data.lastName });
        this.setState({ middleName: response.data.data.middleName });
        // window.localStorage.setItem('username', `${response.data.data.firstName} ${response.data.data.lastName}`);
        this.setState({ title: response.data.data.title });
        this.setState({ userRole: response.data.data.role });
        let startupCompanyTags = [];
        let startupInvestmentTags = [];
        let investorReferralTags = [];
        let selectedCategoryTags = [];
        let addedByTags = [];
        if (
          response.data.data.companies &&
          response.data.data.companies.length > 0
        ) {
          startupCompanyTags = response.data.data.companies.map((company) => {
            return { id: company._id, name: company.companyName };
          });
        }
        if (
          response.data.data.investments &&
          response.data.data.investments.length > 0
        ) {
          startupInvestmentTags = response.data.data.investments.map(
            (investment) => {
              return { id: investment._id, name: investment.name };
            }
          );
        }
        if (
          response.data.data.referralsInvestmentIds &&
          response.data.data.referralsInvestmentIds.length > 0
        ) {
          investorReferralTags = response.data.data.referralsInvestmentIds.map(
            (referral) => {
              return { id: referral._id, name: referral.name };
            }
          );
        }
        if (
          response.data.data.investorCategories &&
          response.data.data.investorCategories.length > 0
        ) {
          selectedCategoryTags = response.data.data.investorCategories.map(
            (category) => {
              return { id: category, name: category };
            }
          );
        }
        if (response.data.data.invitationFrom) {
          addedByTags.push({
            id: response.data.data.invitationFrom._id,
            name: response.data.data.invitationFrom.name,
          });
        }
        this.setState({
          startupCompanyTags,
          investorReferralTags,
          selectedCategoryTags,
          startupInvestmentTags,
          addedByTags,
        });
        this.setState({ notes: response.data.data.notes });
        this.setState({ emailAddress: response.data.data.emailAddress });
        this.setState({ mobile: response.data.data.mobile });
        this.setState({ office: response.data.data.office });
        this.setState({ address: response.data.data.address });
        this.setState({ addedOn: response.data.data.createdAt });
        this.setState({
          addedBy: response.data.data.invitationFrom
            ? response.data.data.invitationFrom.name
            : "",
        });
        this.setState({
          fullAddress:
            (response.data.data.address
              ? response.data.data.address + "," + ""
              : "") +
            (response.data.data.address2
              ? response.data.data.address2 + "," + ""
              : "") +
            (response.data.data.city
              ? response.data.data.city + "," + ""
              : "") +
            (response.data.data.state
              ? response.data.data.state + "," + ""
              : "") +
            (response.data.data.zipcode
              ? response.data.data.zipcode + "," + ""
              : "") +
            "",
        });
        if (response.data.data && response.data.data.role !== "005") {
          this.setState({
            company: response.data.data.companyName
              ? response.data.data.companyName
              : response.data.data.companyId &&
                response.data.data.companyId.companyName
                ? response.data.data.companyId.companyName
                : "",
          });
        } else {
          this.setState({
            company: response.data.data.investorCompanyName
              ? response.data.data.investorCompanyName
              : response.data.data.companyName
                ? response.data.data.companyName
                : "",
          });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getReferalAsCommaSeparatedString = (referals) => {
    const referalString = referals.map((e) => e.name).join(" , ");
    return referalString ? referalString : "-";
  };

  getInvestmentAsCommaSeparatedString = (investments) => {
    const investmentString = investments.map((e) => e.name).join(" , ");
    return investmentString ? investmentString : "-";
  };

  getCategoryAsCommaSeparatedString = (categories) => {
    const categoryString = categories.map((e) => e.name).join(" , ");
    return categoryString ? categoryString : "-";
  };
  mapTags(tags) {
    if (!tags) {
      tags = this.state.searchInputTags;
    }
    let tagIds = [];
    if (tags && tags.length > 0) {
      tagIds = tags.map((tag) => tag.name);
    }
    return tagIds;
  }
  toggleArrow(fieldType) {
    let arrowType = this.state.arrowType;
    let $sort = {};
    if (arrowType[fieldType] === "menu-icon") {
      arrowType[fieldType] = "up-arrow";
      $sort[fieldType] = -1;
    } else {
      arrowType[fieldType] = "menu-icon";
      $sort[fieldType] = 1;
    }
    this.getUserInvestmentHistory(false, this.mapTags(), $sort);
    this.setState({ arrowType });
  }

  getUserInvestmentHistory(isFromLoadMore, tags, $sort) {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem("token");
    const currentPage =
      this.state.exitedUsersPagination && isFromLoadMore
        ? this.state.exitedUsersPagination.currentPage + 1
        : 1;
    const data = {
      page: currentPage,
      limit: common.paginationLimit,
      $sort: $sort ? $sort : null,
    };
    if (tags && tags.length > 0) {
      data.tags = tags;
    }
    axios
      .post(
        `${common.path.base_url}/getInvestorInvestmentHistory/${this.userId}`,
        data,
        {
          headers: {
            Authorization: tokenpass,
          },
        }
      )
      .then((response) => {
        temp.setState({ loading: false });
        temp.setState({ totalInvestment: response.data.totalInvestments });
        temp.setState({
          totalInvestmentAmount: response.data.totalInvestmentsAmount,
        });
        if (response.data.data && response.data.data.length > 0) {
          temp.setState({ userInvestmentHistory: response.data.data });
        }
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        temp.setState({ loading: false });
      });
  }
  mapDateFormat(mongoDate) {
    if (!mongoDate) return "N/A";
    const monthNames = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    return monthNames[monthIndex] + "/" + day + "/" + year;
  }
  render() {
    const username = window.localStorage.getItem("username");
    const id = window.localStorage.getItem("id");
    return (
      <div>
        {this.state.loading ? <LoadingSpinner /> : ""}
        <Header username={username} role={this.state.Role} />
        <div className="profil_sec">
          <div className="container">
            <div className="heading">
              <h3>View Profile</h3>
              {!this.userId && (
                <div className="change-password">
                  <a
                    href="#changePassword"
                    id="openPasswordModal-btn"
                    className="add-button"
                    data-toggle="modal"
                  >
                    Click here
                  </a>{" "}
                  to change your Password
                </div>
              )}
            </div>
            <div className="profil_card">
              <div className="main-container">
                <div className="main-box">
                  <div className="img-box">
                    <img
                      src={`${common.path.base_url}/getImage?id=${this.state.id}`}
                      alt="Profile"
                    />
                  </div>
                  <div className="detail-box">
                    {this.state.firstNamePreferred ? (
                      <h2>
                        {this.state.firstName} ({this.state.firstNamePreferred}){" "}
                        {this.state.lastName}
                      </h2>
                    ) : (
                      <h2>
                        {this.state.firstName} {this.state.lastName}
                      </h2>
                    )}
                    {/* <div className="div-text text-centre"> */}
                    {this.state.title && this.state.company && (
                      <label>
                        {this.state.title ? this.state.title : "-"}&nbsp;
                        <span>at&nbsp;</span>&nbsp;
                        {this.state.company ? this.state.company : "-"}
                      </label>
                    )}
                    {/* </div> */}
                    <p>
                      {this.state.firstName} <span>(Legal)</span>
                    </p>
                    <p>
                      {this.state.fullAddress ? this.state.fullAddress : ""}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() =>
                    this.props.history.push(`/profile?id=${this.userId}`)
                  }
                  data-color="#fc6c85"
                  className="tsg-btn"
                >
                  EDIT
                </button>
              </div>

              {/* <div className="row mb-4">
                                <div className="col-md-4 col-md-offset-4">
                                    <div className="box-item box-item-3">
                                        <div className="img-box">
                                            <img src={`${common.path.base_url}/getImage?id=${this.state.id}`} alt="Profile" />
                                        </div>
                                        {
                                            this.state.firstNamePreferred ?
                                                <h2>{this.state.firstName} ({this.state.firstNamePreferred}) {this.state.lastName}</h2> :
                                                <h2>{this.state.firstName} {this.state.lastName}</h2>


                                        }

                                        <div className="div-text text-centre">
                                            <label>{this.state.title ? this.state.title : '-'}&nbsp;<span>at</span>&nbsp;{this.state.company}</label>
                                        </div>
                                        <div className="div-text text-centre">
                                            <label>{this.state.firstName} &nbsp;<span>(Legal)</span></label>
                                        </div>
                                        <div className="div-text text-centre">
                                            <label>{this.state.address}</label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                  <div className="box-item">
                    <p>Email Address</p>
                    <label>
                      {this.state.emailAddress.length > 25 ? (
                        <marquee>{this.state.emailAddress}</marquee>
                      ) : (
                        this.state.emailAddress
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                  <div className="box-item">
                    <p>Mobile</p>
                    <label>{this.state.mobile}</label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                  <div className="box-item">
                    <p>Office</p>
                    <label>{this.state.office ? this.state.office : "-"}</label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                  <div className="box-item">
                    <p>Added On:</p>
                    <label>
                      {this.state.addedOn
                        ? this.mapDateFormat(this.state.addedOn)
                        : "-"}
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                  <div className="box-item">
                    <p>Added By:</p>
                    {/* <label>{this.state.addedBy ? this.state.addedBy : '-'}</label> */}
                    <div className="form-group investor-companies-list">
                      {/* <label className="col-lg-4 col-sm-3 col-md-3 control-label"></label> */}
                      {/* <div className="col-lg-8 col-md-8 col-sm-8 "> */}
                      <div>
                        {this.state.investorReferralTags.length > 0 &&
                          this.state.investorReferralTags.map((tag) => {
                            return (
                              <span
                                key={tag.id}
                                className="tag label label-info label-text"
                              >
                                <span>{tag.name}</span>
                                {/* <a><i className="remove glyphicon glyphicon-remove-sign glyphicon-white" onClick={() => this.removeReferralTag(tag)}></i></a> */}
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {this.userId != id &&
                  (this.state.userRole === "001" || "002") && (
                    <>
                      <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                        <div className="box-item">
                          <p>Referred By Startup</p>
                          <label>
                            {this.getReferalAsCommaSeparatedString(
                              this.state.investorReferralTags
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                        <div className="box-item">
                          <p>Tags</p>
                          <label>
                            {this.getCategoryAsCommaSeparatedString(
                              this.state.selectedCategoryTags
                            )}
                          </label>
                        </div>
                      </div>
                    </>
                  )}

                {/* {
                                    this.state.userRole === '001' &&
                                    <>
                                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div className="box-item">
                                                <p>Referred By</p>
                                                <label>{this.getReferalAsCommaSeparatedString(this.state.investorReferralTags)}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div className="box-item">
                                                <p>Tags</p>
                                                <label>{this.getCategoryAsCommaSeparatedString(this.state.selectedCategoryTags)}</label>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.userRole === '002' &&
                                    <>
                                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div className="box-item">
                                                <p>Referred By</p>
                                                <label>{this.getReferalAsCommaSeparatedString(this.state.investorReferralTags)}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div className="box-item">
                                                <p>Tags</p>
                                                <label>{this.getCategoryAsCommaSeparatedString(this.state.selectedCategoryTags)}</label>
                                            </div>
                                        </div>
                                    </>
                                } */}
              </div>
              {this.userId != id &&
                (this.state.userRole === "001" || "002") && (
                  <>
                    <hr />
                    <div>
                      <h4>Investment History</h4>
                      <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="detail-card">
                            <label>Total Investments</label>
                            <h4>
                              {this.state.totalInvestment
                                ? this.state.totalInvestment
                                : "-"}
                            </h4>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="detail-card">
                            <label>Total Investment Amount</label>
                            {this.state.totalInvestmentAmount ? (
                              <NumberFormat
                                className="number-text"
                                value={this.state.totalInvestmentAmount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              <h4>-</h4>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="detail-card">
                            <label>Total Return</label>
                            <h4>-</h4>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <div className="detail-card">
                            <label>Total Profit</label>
                            <h5>-</h5>
                          </div>
                        </div>
                      </div>
                      {/* className={`arrow ${this.state.arrowType['user.name']}`}  */}
                      <div className="custom-table-view table-responsive">
                        <table className="table table-custom sortable table-border">
                          <thead>
                            <tr>
                              <th>
                                INVESTMENT NAME{" "}
                                <img
                                  src={downarrow}
                                  className={`menu-icon  ${this.state.arrowType["investmentName"]}`}
                                  onClick={() =>
                                    this.toggleArrow("investmentName")
                                  }
                                ></img>
                              </th>
                              <th>
                                STARTUP NAME{" "}
                                <img
                                  src={downarrow}
                                  className={`menu-icon  ${this.state.arrowType["companyName"]}`}
                                  onClick={() =>
                                    this.toggleArrow("companyName")
                                  }
                                ></img>
                              </th>
                              <th>
                                INVESTMENT AMOUNT{" "}
                                <img
                                  src={downarrow}
                                  className={`menu-icon  ${this.state.arrowType["amount"]}`}
                                  onClick={() => this.toggleArrow("amount")}
                                ></img>
                              </th>
                              <th>
                                DATE{" "}
                                <img
                                  src={downarrow}
                                  className={`menu-icon  ${this.state.arrowType["fundedDate"]}`}
                                  onClick={() => this.toggleArrow("fundedDate")}
                                ></img>
                              </th>
                              <th>STAGE</th>
                              <th>
                                RETURN AMOUNT
                                <img
                                  src={downarrow}
                                  className="menu-icon"
                                ></img>
                              </th>
                              <th>
                                PROFIT %{" "}
                                <img
                                  src={downarrow}
                                  className="menu-icon"
                                ></img>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.userInvestmentHistory.length > 0 &&
                              this.state.userInvestmentHistory.map((user) => {
                                return (
                                  <tr key={user._id}>
                                    <td className="grey-text">
                                      {user.investmentName}
                                    </td>
                                    <td className="grey-text">
                                      {user.companyName}
                                    </td>
                                    <td className="grey-text">
                                      {user.amount
                                        ? "$" + user.amount.$numberDecimal
                                        : "-"}
                                    </td>
                                    <td className="grey-text">
                                      {" "}
                                      {this.mapDateFormat(user.fundedDate)}
                                    </td>
                                    <td className="grey-text">{user.stage}</td>
                                    <td className="grey-text">-</td>
                                    <td className="green-text">-</td>
                                  </tr>
                                );
                              })}
                            {this.state.userInvestmentHistory.length === 0 && (
                              <tr>
                                <td> No investors history found!</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              {/* {
                                this.state.userRole === '005' &&
                                <div className="row">
                                    <div className="col-lg-4 col-md-4col-sm-4 col-xs-12">
                                        <div className="box-item">
                                            <p>Referred by Startup</p>
                                            <label>{this.getReferalAsCommaSeparatedString(this.state.investorReferralTags)}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="box-item">
                                            <p>Previously Invested In</p>
                                            <label> {this.getInvestmentAsCommaSeparatedString(this.state.startupInvestmentTags)}</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <div className="box-item">
                                            <p>Tags</p>
                                            <label>{this.getCategoryAsCommaSeparatedString(this.state.selectedCategoryTags)}</label>
                                        </div>
                                    </div>
                                </div>
                            } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Profile);
