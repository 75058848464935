import React from 'react'
import Header from '../../../components/Header';
import TransactionHistory from '../components/TransactionHistory'

const App = () => (
    <>
        <Header
        username={window.localStorage.getItem('username')}
        role={window.localStorage.getItem('role')}
        />
        <TransactionHistory/>
    </>
)

export default App