import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ReactTags from 'react-tag-autocomplete';
import swal from 'sweetalert';
import common from '../../common';
import disbursement from '../../images/icons/disbursement.png';
import downarrow from '../../images/icons/downarrow.svg';
import exitright from '../../images/icons/exitright.svg';
import search from '../../images/icons/search.svg';
import warning from '../../images/icons/warning.svg';
import './StartupDetail.scss';

class ExitProcessEscrow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { name: 'Previously invested in this Startup investment ', id: 1, isChecked: false },
                { name: 'Referred by Startup ', id: 2, isChecked: false },
                { name: 'Previously invested in any TSG investment', id: 3, isChecked: false },
                { name: 'Previously invited to a TSG investment', id: 4, isChecked: false },
            ],
            pagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isFilterActive: false,
            tags: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
            },
            $sort: null,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.state.pagination.currentPage < this.state.pagination.totalPages && !this.state.isFilterActive)) {
        }
    };
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
    }
    handleAddition(tag) {
        if (tag && tag.id && tag.name) {
            const isExist = this.state.tags.filter(item => item.id === tag.id);
            if (isExist && isExist.length > 0) {
                return;
            }
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
        }
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessEscrowUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getExitProcessEscrowUsers(false, this.mapTags())
        });
    }
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        const selectedFilters = this.mapSelectedFilter(this.state.options);
        this.props.getExitProcessEscrowUsers(selectedFilters, this.state.searchInputTags, this.mapTags(), $sort);
        this.setState({ arrowType, $sort });
    }
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }
    setReturnEscrowAmount = _.debounce((value, formattedValue, id) => {
        if (!value) {
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        const data = {
            escrowReturnAmount: value,
            formattedEscrowReturnAmount: formattedValue.slice(1),
            processId: id,
        }
        axios.post(`${common.path.base_url}/setEscrowReturnAmount`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                // this.props.getExitProcessEscrowUsers(false);
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);

    setEscrowholdBackPercentage = _.debounce((val, value, formattedValue, id) => {
        if (!value) {
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        const data = {
            escrowHoldbackPercentage: value,
            formattedescrowHoldbackPercentage: formattedValue,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setEscrowHoldBackPercentage`, data, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                // this.props.getExitProcessEscrowUsers(false);
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });

                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }, 800);

    setEscrowDisbursementDate(date, id) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            escrowDisbursementDate: date,
            processId: id,
        }
        axios.post(`${common.path.base_url}/setEscrowDisbursementDate`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false });
                temp.props.getExitProcessEscrowUsers(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    exitDisburement(user, amount) {
        let temp = this;
        temp.setState({ loading: true });
        temp.props.showLoading(true);
        const token = window.localStorage.getItem('token');
        let data = {
            legalName: this.props.investmentData.legalName,
            investmentId: user.investmentId,
            userId: user.user._id,
            investmentProcessId: user._id,
            isEscrow: true,
            emailAddress: user.emailId,
            userName: user.name,
            investmentName: user.investmentName,
            disbursementAmount: Number(amount.replaceAll(",", "")),
        };
        axios.post(`${common.path.base_url}/exitDisbursement`, data, {
            headers: {
                Authorization: token,
            }
        }).then((response) => {
            temp.props.showLoading(false);
            temp.setEscrowDisbursementDate(new Date(), user._id);
            swal({
                title: "Success",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
            }).then((response) => {
            });
            
        }).catch((error) => {
            temp.props.showLoading(false);
            temp.setState({ loading: false });
            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            }
            else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });

            }
            else {
                swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                });
            }
        });
    }

    render() {
        return (
            <div className="startup-box-wrapper-main margin-zero">
                <div className='header-investment exit-in-process'>
                    <div className="row searchpaddingtwopart">
                        <div className='search' style={{ width: '88%', position: 'relative' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                suggestions={this.state.suggestions}
                                tags={this.state.searchInputTags}
                                handleDelete={this.handleSearchInputDelete.bind(this)}
                                handleAddition={this.handleSearchInputAddition.bind(this)}
                            />
                            <img src={search} className="search-icon"></img>
                        </div>

                        <div className="all">
                            <div className="select-box">
                                <div className='status'>
                                    <select className='status-box' onChange={(event) => this.props.getExitProcessEscrowUsers(false, this.mapTags(), this.state.$sort, event.target.value)}>
                                        <option value="all">All Investment</option>
                                        {this.props.companyInvestmentsData && this.props.companyInvestmentsData.map((item) => {
                                            return <option value={item.id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-table-view table-responsive table-disbursement">
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th>INVESTOR <img src={downarrow} className="menu-icon"></img></th>
                                    <th>WALLET STATUS <img src={downarrow} className="menu-icon"></img></th>
                                    <th>COMPANY NAME <img src={downarrow} className="menu-icon"></img></th>
                                    <th>NET INVESTMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                    <th>ESCROW DISBURSEMENT AMOUNT<img src={downarrow} className="menu-icon"></img></th>
                                    {/* <th>ESCROW HOLDBACK PERCENTAGE <img src={downarrow} className="menu-icon"></img></th> */}
                                    <th>INITIAL DISBURSEMENT AMOUNT <img src={downarrow} className="menu-icon"></img></th>
                                    <th>INITIAL DISBURSEMENT DATE <img src={downarrow} className="menu-icon"></img></th>
                                    <th>INITIAL DISBURSEMENT STATUS<img src={downarrow} className="menu-icon"></img></th>
                                    <th>VIEW DOC.</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.exitInProcessEscrowUsers.length > 0 &&
                                    this.props.exitInProcessEscrowUsers.map(user => {


                                        return (
                                            <tr key={user._id}>
                                                <td>
                                                    {user.name}
                                                    <div className="tooltipcover">
                                                        <span className="tooltiptext">{user.isAcountVerified ? 'Verified' : 'Address not verified'}</span>
                                                        <img src={user.isAcountVerified ? exitright : warning} className="menu-icon"></img>
                                                    </div>
                                                </td>
                                                <td>
                                                    {user.user.verivendId ? "Created" : "Not Created"}
                                                </td>
                                                <td>
                                                    {user.companyName}
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={user.amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />

                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        value={Number(user.disbursement1).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                </td>
                                                <td>
                                                    {this.mapDateFormat(user.disbursementDate)}
                                                </td>
                                                <td>
                                                    <span style={{ color: "#00CC00" }}>{user.disbursementFunded.toString().toUpperCase()}</span>
                                                </td>
                                                <td>
                                                    <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.subscriptionEnvId, 'Subscription')}>View QSA</a><br />
                                                    <a style={{ color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.viewDocumentRedirect(user.w9EnvId, this.getUserCitizenForm(user.isForeignCitizen))}>
                                                        {
                                                            user.isForeignCitizen ? 'View W-8' : 'View W-9'
                                                        }
                                                    </a>
                                                </td>
                                                <td>
                                                    {/* <div className="tooltipcover-intial-notification">
                                                        <span className="tooltiptext">Intial notification</span>
                                                        <img src={bell} className="menu-icon"
                                                            data-target={`#intialNotificationModal`}
                                                            data-toggle={`modal`}
                                                            onClick={() => {
                                                                this.setState(this.sendInitialNotificationChild.resetIntialNotificationData(), this.sendInitialNotificationChild.setData({
                                                                    user: user.user,
                                                                    companyName: this.props.investmentData.companyName,
                                                                    investmentId: user.investmentId,
                                                                    isFromMultipleSendMessage: false,
                                                                    investmentType: this.props.investmentData.exitProcessType,
                                                                    correspondingllcname: this.props.investmentData.llcName,
                                                                    acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                    ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                }));
                                                            }}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <div className="tooltipcover-communication">
                                                        <span className="tooltiptext">Communication</span>
                                                        <img
                                                            src={chat}
                                                            className="menu-icon"
                                                            data-target={`#detailCommunicationModal`}
                                                            data-toggle={`modal`}
                                                            alt=""
                                                            onClick={() => {
                                                                this.setState(this.sendDetailCommunicationChild.resetIntialNotificationData(), this.sendDetailCommunicationChild.setData({
                                                                    user: user.user,
                                                                    companyName: this.props.investmentData.companyName,
                                                                    investmentId: user.investmentId,
                                                                    isFromMultipleSendMessage: false,
                                                                    investmentType: this.props.investmentData.exitProcessType,
                                                                    correspondingllcname: this.props.investmentData.llcName,
                                                                    acquiringcompanyname: this.props.investmentData.acquiringCompanyName,
                                                                    ipodate: this.mapDateFormat(this.props.investmentData.lockupDate),
                                                                    initialpayoutdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                    escrowpercentageholdback: user.escrowHoldbackPercentage,
                                                                    escrowexpirationdate: this.mapDateFormat(this.props.investmentData.disbursementDate),
                                                                }));
                                                            }}
                                                        />
                                                    </div> */}
                                                    <div className="tooltipcover-communication">
                                                        <span className="tooltiptext">Disbursement</span>
                                                        <img
                                                            src={disbursement}
                                                            alt=""
                                                            className="menu-icon"
                                                            style={{ height: "20px" }}
                                                            onClick={() => {
                                                                if(Number(user.disbursement2) > 0) {
                                                                    this.exitDisburement(user, Number(user.disbursement2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
                                                                } else {
                                                                    swal({
                                                                        title: "Error!",
                                                                        text: "The amount should be greater than zero.",
                                                                        icon: "error",
                                                                        button: "Ok!",
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.props.exitInProcessEscrowUsers.length === 0 &&
                                    <tr>
                                        <td> No investors found for Exit In Process!</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        );
    }

}

export default ExitProcessEscrow;