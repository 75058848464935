import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import LaddaButton, { EXPAND_RIGHT, XS } from "react-ladda";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";
import common from "../../../common";
import RequiredSign from "../../../components/RequiredSign";
import "./TSGReview.scss";

class UploadDocInvestmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      company: "",
      mobile: "",
      referredBy: "",
      email: "",
      Interest: "",
      city: "",
      state: "",
      country: "",
      cityValidation: "",
      stateValidation: "",
      countryValidation: "",
      firstNameValidation: "",
      middleNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      InterestValidation: "",
      isValid: false,
      isaccreditedInvestor: false,
      key: 0,
      recaptchaCode: "",
    };

    this.selectorRef = React.createRef(null);
  }

  componentWillMount() { }

  formValidaton(id, val) {
    if (id === "firstName") {
      let name = val.trim(" ");
      if (name === "") {
        this.setState({
          firstNameValidation: "First Name should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          firstNameValidation: "",
        });
      }
    } else if (id === "middleName") {
      let name = val.trim(" ");
      if (name === "") {
        this.setState({
          middleValidation: "Middle Name should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          middleValidation: "",
        });
      }
    } else if (id === "lastName") {
      let name = val.trim(" ");
      if (name === "") {
        this.setState({
          lastNameValidation: "Last Name should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          lastNameValidation: "",
        });
      }
    } else if (id === "company") {
      let Company = val.trim(" ");
      if (Company === "") {
        this.setState({
          companyValidation: "Company name should not be empty*",
        });
      } else {
        this.setState({
          companyValidation: "",
        });
      }
    } else if (id === "title") {
      let title = val.trim(" ");
      if (title === "") {
        this.setState({
          titleValidation: "Title should not be empty*",
        });
      } else {
        this.setState({
          titleValidation: "",
        });
      }
    } else if (id === "email") {
      let email = val.trim(" ");
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = emailRegex.test(email);
      if (email === "") {
        this.setState({
          emailValidation: "Email should not be empty*",
          isValid: false,
        });
      } else if (res !== true) {
        this.setState({
          emailValidation: "Please enter valid email*",
          isValid: false,
        });
      } else {
        this.setState({
          emailValidation: "",
        });
      }
    } else if (id === "Interest") {
      let Interest = val.trim(" ");
      if (Interest === "") {
        this.setState({
          InterestValidation: "Interest should not be empty*",
          isValid: false,
        });
      } else {
        this.setState({
          InterestValidation: "",
        });
      }
    } else if (id === "mobile") {
      let mobile = val.trim(" ");
      let mobileRegex =
        /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
      let res = mobileRegex.test(mobile);
      if (res !== true) {
        let swissRegex =
          /^(\+?)(\d{2,4})(\s?)(\-?)((\(0\))?)(\s?)(\d{2})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/;
        let swissres = swissRegex.test(mobile);

        if (swissres !== true) {
          this.setState({
            mobileValidation: "Please enter valid mobile number",
            isValid: false,
          });
        } else {
          this.setState({
            mobileValidation: "",
          });
        }
      } else {
        this.setState({
          mobileValidation: "",
        });
      }
    }

    // let recaptcha = this.state.recaptchaCode;
    // recaptcha = recaptcha.trim(" ");
    // console.log(recaptcha);
    // if ( recaptcha === '' ) {
    //     this.setState({
    //         isValid: false,
    //     });
    // }
  }

  checkInvitaionFormValidity() {
    if (
      !_.isEmpty(
        this.state.firstName.trim(" ") &&
        this.state.lastName.trim(" ") &&
        this.state.emailValidation === "" &&
        this.state.title.trim(" ") &&
        this.state.company.trim(" ") &&
        this.state.Interest.trim(" ") &&
        this.state.mobile.trim(" ") &&
        this.state.mobile.length >= 13 &&
        this.state.referredBy.trim(" ") &&
        this.state.city.trim(" ") &&
        this.state.state.trim(" ") &&
        this.state.country.trim(" ")
      ) &&
      this.state.isaccreditedInvestor &&
      this.state.recaptchaCode
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  }

  handleChange(id, newValue) {
    if (
      id === "accreditedInvestor" &&
      this.state.isaccreditedInvestor === false
    ) {
      this.setState({ isaccreditedInvestor: true });
    } else if (
      id === "accreditedInvestor" &&
      this.state.isaccreditedInvestor === true
    ) {
      this.setState({ isaccreditedInvestor: false });
    } else {
      this.setState({ [id]: `+${newValue}` });
    }
    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  }

  sendTsgReview() {
    this.setState({ loadinglada: true });
    axios
      .post(
        `${common.path.base_url}/sendtInterestForm`,
        {
          firstName: this.state.firstName.trim(" "),
          middleName: this.state.middleName.trim(" "),
          lastName: this.state.lastName.trim(" "),
          company: this.state.company.trim(" "),
          title: this.state.title.trim(" "),
          interest: this.state.Interest.trim(" "),
          city: this.state.city.trim(" "),
          state: this.state.state.trim(" "),
          country: this.state.country.trim(" "),
          email: this.state.email.trim(" "),
          mobile: this.state.mobile,
          referredBy: this.state.referredBy,
          isAccreditedInvestor: this.state.isaccreditedInvestor,
          recaptchaCode: this.state.recaptchaCode,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({ loadinglada: false });
        if (response && response.data) {
          document.getElementById("cancelReviewForm").click();
          this.resetForm();
          swal({
            title: "Request Sent!",
            text: response.data.message,
            icon: "success",
            button: "Ok!",
          });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response.data.code === 204) {
          window.localStorage.clear();
          window.location.href = "/";
        } else {
          this.setState({ loadinglada: false });
          if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          } else {
            swal({
              title: "Network Error!",
              text:
                error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        }
      });
  }

  resetForm() {
    const key = this.state.key + 1;
    this.setState({
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      company: "",
      email: "",
      mobile: "",
      Interest: "",
      referredBy: "",
      city: "",
      state: "",
      country: "",
      firstNameValidation: "",
      middleNameValidation: "",
      lastNameValidation: "",
      titleValidation: "",
      companyValidation: "",
      emailValidation: "",
      mobileValidation: "",
      InterestValidation: "",
      isValid: false,
      isaccreditedInvestor: false,
      key,
    });
  }

  reCaptchaChange(e) {
    const token = this.selectorRef.current.getValue();
    this.setState({ recaptchaCode: token });

    setTimeout(() => {
      this.checkInvitaionFormValidity();
    }, 100);
  }

  render() {
    return (
      <div>
        {/* <!-- Invite Modal HTML --> */}
        <div id="tsgReviewModal" className="modal fade">
          <div className="modal-dialog" key={this.state.key}>
            <div className="modal-content modal-content-custom">
              <div className="modal-header">
                <h4 className="modal-title">TSG Membership Application</h4>
              </div>
              <div className="modal-body form-horizontal">
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    First Name <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <div>
                      <input
                        className="form-control"
                        id="firstName"
                        type="text"
                        onKeyUp={(event) =>
                          this.formValidaton(
                            event.target.id,
                            event.target.value
                          )
                        }
                        onChange={(event) =>
                          this.handleChange(event.target.id, event.target.value)
                        }
                        placeholder="First Name"
                        value={this.state.firstName}
                      />
                      <p>{this.state.firstNameValidation}</p>
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                                    <label className="col-lg-4 col-sm-3  col-md-3 control-label">Middle Name :</label>
                                    <div className="col-lg-8 col-sm-8 col-md-8">
                                        <input className="form-control" id="middleName" type="text"
                                            onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            placeholder='Middle Name'
                                            value={this.state.middleName} />
                                    </div>
                                </div> */}

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Last Name <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="lastName"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Last Name"
                      value={this.state.lastName}
                    />
                    <p>{this.state.lastNameValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Company <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="company"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Company"
                      value={this.state.company}
                    />
                    <p>{this.state.companyValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Title <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="title"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Title"
                      value={this.state.title}
                    />
                    <p>{this.state.titleValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Email Address <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="email"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      type="text"
                      placeholder="Email Address"
                      value={this.state.email}
                    />
                    <p>{this.state.emailValidation}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Mobile <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8 mobile-num">
                    <ReactPhoneInput
                      country={"us"}
                      id="mobile"
                      onChange={(value) => this.handleChange("mobile", value)}
                      placeholder="Mobile"
                      disableAreaCodes={true}
                      countryCodeEditable={false}
                      autoComplete="none"
                      value={this.state.mobile}
                    />
                    <p>{this.state.mobileValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    City <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="City"
                      value={this.state.city}
                    />
                    <p>{this.state.cityValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    State <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="State"
                      value={this.state.state}
                    />
                    <p>{this.state.stateValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Country of Residence <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="country"
                      type="text"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Country of Residence"
                      value={this.state.country}
                    />
                    <p>{this.state.countryValidation}</p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    Interest <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <textarea
                      className="form-control"
                      name="preferredStockTerms"
                      id="Interest"
                      type="message"
                      placeholder="Please tell us a little about your interest in investing through TSG"
                      rows="4"
                      cols="50"
                      onKeyUp={(event) =>
                        this.formValidaton(event.target.id, event.target.value)
                      }
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      value={this.state.Interest}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-lg-4 col-sm-3  col-md-3 control-label">
                    How I Heard of TSG <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <input
                      className="form-control"
                      id="referredBy"
                      type="text"
                      onChange={(event) =>
                        this.handleChange(event.target.id, event.target.value)
                      }
                      placeholder="Name of friend, colleague, company, etc."
                      value={this.state.referredBy}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className="col-lg-4 col-sm-3  col-md-3 control-label"
                    for={`accreditedInvestor`}
                  >
                    I am an Accredited Investor <RequiredSign /> :
                  </label>
                  <div className="col-lg-8 col-sm-8 col-md-8 checkbox-margin-review">
                    <input
                      type="checkbox"
                      id={`accreditedInvestor`}
                      onChange={(event) => this.handleChange(event.target.id)}
                      checked={this.state.isaccreditedInvestor}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-offset-3 col-md-8">
                    <ReCAPTCHA
                      sitekey="6LdBIOcfAAAAAHu3eWs7H1Su65gV15U7Y-ZeDIHb"
                      onChange={() => this.reCaptchaChange()}
                      ref={this.selectorRef}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <LaddaButton
                    id="lada-btn"
                    loading={this.state.loadinglada}
                    onClick={() => this.sendTsgReview()}
                    data-color="#eee"
                    data-size={XS}
                    className={!this.state.isValid ? "disable" : ""}
                    data-style={EXPAND_RIGHT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                  >
                    SUBMIT
                  </LaddaButton>
                  <input
                    type="button"
                    id="cancelReviewForm"
                    className="btn btn-default"
                    data-dismiss="modal"
                    value="CANCEL"
                    onClick={() => this.resetForm()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UploadDocInvestmentModal;
