import React from 'react';
import DetailView from '../../components/DetailView/DetailView';
import './Start.scss';

const redirect = () => {
    const url = new URL(window.location.href);
    const investorId = url.searchParams.get('investorId');
    if (investorId) {
        window.location.href = `/investor-dashboard?investorId=${investorId}`
    } else {
        window.location.href = `/investor-dashboard`;
    }
}

const redirectToTransactionHistory = () => {
    window.location.href = `/transaction-history`
}

const DashboardStart = (props) => (
    <>
        <div className='back-dashboard' >
            <button className='btn btn-danger' onClick={() => redirect(props)} >Back to Dashboard</button>
            <button className='btn btn-danger mt-sm-0 mt-3' onClick={() => redirectToTransactionHistory()}>Transaction History</button>
        </div>
        <div className="new_investment">
            <div className="container-fluid">
                <h2>Member Wallet Details</h2>
            </div>
            <div className="invitations_sec new_investment">
                <div className="container-fluid">
                    <div className="row">
                        <DetailView />
                    </div>
                </div>
            </div>
        </div>
    </>
)


export default DashboardStart