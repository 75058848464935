import React from "react";
import { connect } from "react-redux";
import common from "../../../common";
import completed from "../../../images/assets/images/agree.svg";
import { path } from "../../imagesPath";
import { getCompanyLogoFromS3 } from "../../shared/helper";
import BrockerageAccountModel from "./BrockerageAccountModel";
import "./ExitInProcessInvestments.scss";
import VerifyAddressModel from "./VerifyAddressModel";

const ExitInProcessInvestmentCard = (props) => {
  const { exitedInvestment, investorUserData } = props;
  const mapDateFormat = (mongoDate) => {
    if (!mongoDate) return null;
    const monthNames = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let date = new Date(mongoDate);
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    return monthNames[monthIndex] + "/" + day + "/" + year;
  };

  const isBankAccountAdded = () =>
    investorUserData.verivendData
      ? true
      : false;

  const getIsDisbursed = () => {
    return exitedInvestment.investmentprocesses &&
      exitedInvestment.investmentprocesses.disbursementFunded === "Completed" &&
      exitedInvestment.company.exitProcessType !== "acquisitionwithescrow"
      ? <img style={{ float: "right", height: "20px" }} src={completed} />
      : exitedInvestment.investmentprocesses
        && exitedInvestment.investmentprocesses.disbursementFunded === "Completed"
        && exitedInvestment.investmentprocesses.disbursementFunded2 === "Completed"
        && exitedInvestment.company.exitProcessType === "acquisitionwithescrow"
        ? <img style={{ float: "right", height: "20px" }} src={completed} />
        : <></>;
  }


  const roleCode = window.localStorage.getItem('roleCode');

  return (
    <div className="card cursor">
      <div className="exit_box">
        <div className="series_d">
          <img src={getCompanyLogoFromS3(exitedInvestment.company)} alt="" />
        </div>
        <div className="text-content">
          <h2
            className={"exit-investment-title"}
            onClick={(e) =>
              props.urlExitProcess(
                exitedInvestment.investment._id,
                exitedInvestment.investmentprocesses.userId
              )
            }
          >
            {exitedInvestment.investment.name}
            {getIsDisbursed()}
          </h2>
          <h4>
            {exitedInvestment.company.exitProcessType === "ipo"
              ? "IPO"
              : "Acquisition"}
          </h4>
        </div>
        <h4>
          Target Disbursement Date:{" "}
          <span>
            {mapDateFormat(exitedInvestment.company.disbursementDate)}
          </span>
        </h4>
        <div className="row d-flex content-alignment">
          {exitedInvestment &&
            exitedInvestment.company &&
            exitedInvestment.company.exitProcessType === "ipo" ? (
            <>
              <button
                className="btn btn-danger top-margin"
                onClick={() =>
                  props.brokerageAccountClick(props.exitedInvestment)
                }
              >
                {exitedInvestment.investmentprocesses.isAcountVerified
                  ? "Brokerage Account Verified"
                  : "Brokerage Account"}
                {exitedInvestment.investmentprocesses.isAcountVerified ? (
                  <i className="fa fa-pencil edit-details"></i>
                ) : (
                  ""
                )}
              </button>
            </>
          ) : (roleCode === "005" && exitedInvestment.company.exitProcessType !== "ipo" ?
            <button
              className="btn btn-danger top-margin"
              onClick={() =>
                window.location.href = "/investorWallet"
              }
              >
              {
                !isBankAccountAdded()
                ? "Create Wallet"
                : "View Wallet"
              }
            </button>
            :
            <button
            className="btn btn-danger top-margin"
            onClick={() => {
                window.location.href = `/investorWallet?investorId=${investorUserData._id}`;
               }}
            >
             {
                !isBankAccountAdded()
                ? "Create Wallet"
                : "View Wallet"
              }
            </button>
            // <button
            //   className="btn btn-danger top-margin"
            //   onClick={() =>
            //     props.verifyAddressClick(props.exitedInvestment)
            //   }
            // >
            //   {
            //     exitedInvestment.investmentprocesses.isAcountVerified
            //       ? "Address & Wire Verified"
            //       : "Verify Address & Wire"}{" "}
            //   {exitedInvestment.investmentprocesses.isAcountVerified
            //     && exitedInvestment.company.exitProcessType === "ipo" ? (
            //     <i className="fa fa-pencil edit-details"></i>
            //   ) : (
            //     ""
            //   )}
            // </button>
          )}
        </div>
        <div
          className="exit-process-doc"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <a
            className="view-detials-documents"
            alt="view all"
            style={{
              height: "30px",
              display: "flex",
              alignItems: "center",
              padding: "18px",
              whiteSpace: "nowrap",
              lineHeight: "15px",
            }}
            onClick={() =>
              props.urlExitProcess(
                exitedInvestment.investment._id,
                exitedInvestment.investmentprocesses.userId
              )
            }
          >
            View Details and Documents
          </a>
        </div>
      </div>
    </div>
  );
};

const ExitInProcessInvestments = (props) => {
  const { exitInProcessInvestments, fromToDo } = props;
  const { isBlackList } = props;
  let verifyAddressChild,
    inputElement = null;
  let brokerageAccountChild,
    inputElement1 = null;

  const urlExitProcess = (investment, investor) => {
    window.location.href = `/exit-investment-details/${investment}/${investor}`;
  };

  const viewDocumentRedirect = (envId, type) => {
    let queryString = `?envId=${envId}&type=${type}`;
    window.open(
      ` ${common.path.doc_download_url}/downloadPDF${queryString}`,
      "_blank",
      "",
      2
    );
  };

  return (
    <>
      <VerifyAddressModel onRef={(ref) => (verifyAddressChild = ref)} />
      <input
        type="button"
        style={{ display: "none" }}
        data-target={`#verifyAddressModel`}
        ref={(input) => (inputElement = input)}
        data-toggle={`modal`}
      />
      <BrockerageAccountModel onRef={(ref) => (brokerageAccountChild = ref)} />
      <input
        type="button"
        style={{ display: "none" }}
        data-target={`#brockerageAccountModel`}
        ref={(input) => (inputElement1 = input)}
        data-toggle={`modal`}
      />
      <div className="exit_investment">
        {exitInProcessInvestments &&
          !isBlackList &&
          exitInProcessInvestments.length > 0 && (
            <div className="container" style={{ padding: "15px" }}>
              {!fromToDo && (
                <div className="row d-flex">
                  <img
                    className="intial_icon"
                    src={path.exitProcessInvestment}
                  />
                  <div className="col-md-3">
                    <h3 style={{ fontSize: "20px", color: "#383F59" }}>
                      Exits In Process ({exitInProcessInvestments.length})
                    </h3>
                  </div>
                  <div className="col-md-9"></div>
                </div>
              )}
              <div className="row">
                {exitInProcessInvestments.map(
                  (exitInProcessInvestment, index) => {
                    return (
                      <div
                        className="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxl-3"
                        key={exitInProcessInvestment._id + "_" + index}
                      >
                        <ExitInProcessInvestmentCard
                          exitedInvestment={exitInProcessInvestment}
                          investorId={props.investorId}
                          verifyAddressClick={(exitInProcessInvestment) =>
                            props.verifyAddressClick(exitInProcessInvestment)
                          }
                          brokerageAccountClick={(exitInProcessInvestment) =>
                            props.brokerageAccountClick(exitInProcessInvestment)
                          }
                          urlExitProcess={(investment, investor) =>
                            urlExitProcess(investment, investor)
                          }
                          viewDocumentRedirect={(envId, type) =>
                            viewDocumentRedirect(envId, type)
                          }
                          {...props}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    investorUserData: state.investorDashboardData.investorUserData
      ? state.investorDashboardData.investorUserData
      : null,
    exitInProcessInvestments:
      state.investorDashboardData.exitInProcessInvestments.length > 0
        ? state.investorDashboardData.exitInProcessInvestments
        : [],
  };
};

export default connect(mapStateToProps, null)(ExitInProcessInvestments);
