import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";
import NumberFormat from "react-number-format";
import common from "../../../common";
import "./TransactionHistory.scss";

const redirect = () => {
    window.location.href = `/investor-dashboard`;
}

class TransactionHistory extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            userTransactionData: null,
        };
    }

    componentDidMount() {
        this.userId = window.localStorage.getItem("id");
        this.getUserTransactionHistory();
    }

    async getUserTransactionHistory() {
        let temp = this;
        let tokenpass = window.localStorage.getItem("token");

        temp.setState({ loading: true });
        axios
            .get(`${common.path.base_url}/getUserTransactionHistory/${this.userId}`, {
                headers: {
                    Authorization: tokenpass,
                },
            })
            .then((response) => {
                temp.setState({ loading: false });
                temp.setState({ userTransactionData: response.data.data });
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    common.AUTH_EXPIRED === error.response.data.message
                ) {
                    window.localStorage.clear();
                    window.location.href = "/";
                    return;
                } else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                } else {
                    swal({
                        title: "Network Error!",
                        text:
                            error.response &&
                                error.response.data &&
                                error.response.data.message
                                ? error.response.data.message
                                : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    mapDateFormat(mongoDate) {
        if (!mongoDate) return "N/A";
        const monthNames = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + "/" + day + "/" + year;
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="back-dashboard">
                        <button className="btn btn-danger" onClick={() => redirect()}>
                            Back to Dashboard
                        </button>
                    </div>

                    <div className="card my-custom-card">
                        <div
                            className="new_investment"
                            style={{ padding: "0px 10px", marginBottom: 20 }}
                        >
                            <h2>Wallet Withdrawals to Bank</h2>
                        </div>

                        {this.state.userTransactionData &&
                            this.state.userTransactionData.length > 0 ? (
                            <div className="custom-table-view table-responsive">
                                <table className="table table-custom">
                                    <thead>
                                        <tr>
                                            <th>Bank Account</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userTransactionData.map((transaction) => {
                                            return (
                                                <tr key={transaction.transactionId}>
                                                    <td className="grey-text">{transaction.bankAccount}</td>
                                                    <td className="grey-text">
                                                        {this.mapDateFormat(transaction.created)}
                                                    </td>
                                                    <td
                                                        className={
                                                            common.transactionStatus.failed ===
                                                                transaction.status
                                                                ? "transactionColor"
                                                                : common.transactionStatus.success ===
                                                                    transaction.status
                                                                    ? "successTransactionColor"
                                                                    : ""
                                                        }
                                                    >
                                                        {transaction.status}
                                                    </td>
                                                    <td className="grey-text">
                                                        {
                                                            <NumberFormat
                                                                value={transaction.totalAmount}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                fixedDecimalScale={true}
                                                                displayType={"text"}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div>
                                <span style={{ fontSize: "15px", padding: "30px" }}>
                                    No transactions made so far.
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default TransactionHistory;
