import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import common from "../../../../common";
import TButton from "../../../../components/TButton";
import UploadDocumentModal from "../../../../containers/Dashboard/InvestmentUploadDocumentModal/UploadDocument";
import images from "../../../../images";
import "../../../components/PendingCounterSigningInvestments/PendingCounterSigningInvestments.scss";
import { path } from "../../../imagesPath";
import { companyValutionFormat, getInvestmentLogoFromS3, getUserInvestmentAmount, mapDateFormat } from "../../../shared/helper";
import "./DetailView.scss";

const viewDocumentRedirect = (envId, type) => {
  window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

}
const getUserCitizenForm = (isForeignCitizen) => {
  return isForeignCitizen === true ? 'W8' : 'W9'
}
const DocumentAccordionItem = props => {

  const { documents } = props;
  const roleCode = window.localStorage.getItem('roleCode');
  const userId = window.localStorage.getItem('id');
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="folder-wraper">
            <div className="folder-name">
              <img src={images.path.folder} alt=""></img>
              {props.title}
            </div>
            {props.isUploadEnable && (
              <div className="upload-btn" style={{ marginRight: "50px" }}>
                <TButton
                  id="edit-btn"
                  text={`UPLOAD`}
                  onClick={() => props.onClick(props.myInvestment)}
                  tsgBtnClass="tsg-btn"
                />
              </div>
            )}
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      {props.investmentprocesses &&
        props.investmentprocesses.subscriptionEnvId &&
        props.title === "Investment Documents" &&
        !props.investmentprocesses.qsaDocHide && (
          <AccordionItemPanel>
            <div className="accordion-section-inner">
              <div className="accordion-section-inner-file">
                <div className="accordion-left">
                  <div className="file-name">
                    <a
                      className="file-link"
                      onClick={() =>
                        viewDocumentRedirect(
                          props.investmentprocesses.subscriptionEnvId,
                          "Subscription"
                        )
                      }
                      download
                    >
                      QSA Doc
                    </a>
                  </div>
                  <div className="date">&nbsp;</div>
                </div>
                <div className="accordion-right"></div>
              </div>
            </div>
          </AccordionItemPanel>
        )}
      {props.investmentprocesses &&
        props.investmentprocesses.w9EnvId &&
        props.title === "Investment Documents" && (
          <AccordionItemPanel>
            <div className="accordion-section-inner">
              <div className="accordion-section-inner-file">
                <div className="accordion-left">
                  <div className="file-name">
                    <a
                      className="file-link"
                      onClick={() =>
                        viewDocumentRedirect(
                          props.investmentprocesses.w9EnvId,
                          getUserCitizenForm(
                            props.investmentprocesses.isForeignCitizen
                          )
                        )
                      }
                      download
                    >
                      {props.investmentprocesses.isForeignCitizen
                        ? "W-8 Signed Doc."
                        : "W-9 Signed Doc."}
                    </a>
                  </div>
                  <div className="date">&nbsp;</div>
                </div>
                <div className="accordion-right"></div>
              </div>
            </div>
          </AccordionItemPanel>
        )}
      {props.investmentprocesses &&
        props.investmentprocesses.sideletterEnvId &&
        props.title === "Investment Documents" && (
          <AccordionItemPanel>
            <div className="accordion-section-inner">
              <div className="accordion-section-inner-file">
                <div className="accordion-left">
                  <div className="file-name">
                    <a
                      className="file-link"
                      onClick={() =>
                        viewDocumentRedirect(
                          props.investmentprocesses.sideletterEnvId,
                          "sideLetter"
                        )
                      }
                      download
                    >
                      Side Letter
                    </a>
                  </div>
                  <div className="date">&nbsp;</div>
                </div>
                <div className="accordion-right"></div>
              </div>
            </div>
          </AccordionItemPanel>
        )}
      {documents &&
        documents.length > 0 &&
        documents.map((doc) => {
          return (
            <AccordionItemPanel>
              <div className="accordion-section-inner">
                <div className="accordion-section-inner-file">
                  <div className="accordion-left">
                    <div className="file-name">
                      <a
                        className="file-link"
                        target="_blank"
                        href={`${common.path.doc_download_url}/downloadInvestmentDoc?fileId=${doc._id}&id=${doc.investmentId}`}
                        download
                      >
                        {doc.name.split(".")[0]}
                      </a>
                    </div>
                    <div className="date">
                      {mapDateFormat(doc.createdAt)} |{" "}
                      {doc.name.split(".").pop().toUpperCase()}
                    </div>
                  </div>
                  <div className="accordion-right">
                    {(doc.createdBy === userId ||
                      roleCode === "001" ||
                      roleCode === "002") && (
                        <img
                          className="delete-icon"
                          src={images.path.deleteIcon}
                          onClick={() =>
                            props.deleteInvestmentDoc &&
                            props.deleteInvestmentDoc(doc)
                          }
                        />
                      )}
                    {!props.isStatusDisable && (
                      <>
                        {doc && doc.isDownloaded ? (
                          <img
                            className="mailopen-icon"
                            src={images.path.mailopen}
                            alt="open"
                          ></img>
                        ) : (
                          <img
                            className="mail-icon"
                            src={images.path.mail}
                            alt="close"
                          ></img>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </AccordionItemPanel>
          );
        })}
      {props.signOA && props.title === "Investment Documents" && (
        <AccordionItemPanel>
          <div className="accordion-section-inner">
            <div className="accordion-section-inner-file">
              <div className="accordion-left">
                <div className="file-name">
                  <a
                    className="file-link"
                    target="_blank"
                    href={`${common.path.base_url}/getFileNew?fileId=${props.signOA}`}
                    download
                  >
                    Operating Agreement
                  </a>                </div>
                <div className="date">&nbsp;</div>
              </div>
              <div className="accordion-right"></div>
            </div>
          </div>
        </AccordionItemPanel>
      )}
    </AccordionItem>
  )
}

export const Documents = props => {
  const roleCode = window.localStorage.getItem('roleCode');
  const { documents, isExitedProcess } = props.investmentDetailData;
  const { investmentprocesses } = props.investmentDetailData;

  const myDocuments =
    documents &&
    documents
      .filter((doc) => doc.folderName === "My Documents")
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const taxDocuments =
    documents &&
    documents
      .filter((doc) => doc.folderName === "Tax Documents")
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const investmentDocuments =
    documents &&
    documents
      .filter((doc) => doc.folderName === "Investment Documents")
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const otherDocuments =
    documents &&
    documents
      .filter((doc) => doc.folderName === "Other Documents")
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const exitDocuments =
    documents &&
    documents
      .filter((doc) => doc.folderName === "Exit Documents")
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));



  return (
    <div className="upload_document">
      {isExitedProcess === false && (
        <h3>
          Documents
          {roleCode && (roleCode === "001" || roleCode === "002") && (
            <div
              className="upload-btn"
              style={{
                display: "inline-block",
                right: "10px",
                position: "absolute",
              }}
            >
              <TButton
                id="edit-btn"
                onClick={() =>
                  props.onClick(props.myInvestment) && props.uploadClickHnadler()
                }
                text={`UPLOAD`}
                tsgBtnClass="tsg-btn"
              />
            </div>
          )}
        </h3>
      )}

      <div className="accordion-section accordion-section-custom">
        <Accordion allowZeroExpanded="true">
          <DocumentAccordionItem
            title='Tax Documents'
            documents={taxDocuments}
            {...props}
          />
        </Accordion>
        <Accordion allowZeroExpanded="true">
          <DocumentAccordionItem
            title='Investment Documents'
            documents={investmentDocuments}
            investmentprocesses={investmentprocesses}
            {...props}
          />
        </Accordion>
        <Accordion allowZeroExpanded="true">
          <DocumentAccordionItem
            title='Other Documents'
            documents={otherDocuments}
            {...props}
          />
        </Accordion>
        <Accordion allowZeroExpanded="true">
          <DocumentAccordionItem
            title="Exit Documents"
            documents={exitDocuments}
            {...props}
          />
        </Accordion>
        {roleCode === "005" && (
          <Accordion allowZeroExpanded="true">
            <DocumentAccordionItem
              title='My Documents'
              isUploadEnable={true}
              documents={myDocuments}
              isStatusDisable={true}
              {...props}

            />
          </Accordion>
        )}
      </div>
    </div>
  );
}

// Custom hook to listen to window resize events
function useScreenWidthLessThan768() {
  const [isLessThan768, setIsLessThan768] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLessThan768(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect only runs once after initial render

  return isLessThan768;
}

// Higher Order Component to use the hook and pass values as props
function withWindowSize(Component) {
  return function WrappedComponent(props) {
    const size = useScreenWidthLessThan768();
    return <Component {...props} windowSize={size} />;
  };
}

class DetailView extends Component {

  constructor() {
    super()
    this.state = {
      investmentDetailData: null,
      signOA: null,
    };
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    this.investmentId = url.searchParams.get('investmentId');
    this.userId = window.localStorage.getItem('id');
    this.investorId = url.searchParams.get('investorId');
    if (this.investorId) {
      this.userId = this.investorId;
    }

    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/myInvestmentsById/${this.userId}/${this.investmentId}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        axios.get(`${common.path.base_url}/getSignOA/${this.investmentId}`, {
          headers: {
            "Authorization": token
          }
        })
          .then((signOARes) => {
            temp.setState({ loading: false });
            if (signOARes && signOARes.data && signOARes.data.data && signOARes.data.data) {
              temp.setState({ signOA: signOARes.data.data })
            }
            if (response && response.data && response.data.data && response.data.data.length > 0) {
              temp.setState({ investmentDetailData: response.data.data[0] })
            }

          })

      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error! #451220",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  upload() {
    document.getElementById('uploadInvestorDocument-btn').click();
  }
  deleteInvestmentDoc(file) {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        const token = window.localStorage.getItem('token');
        const userId = window.localStorage.getItem('id');
        if (willDelete) {
          this.setState({ loading: true })
          axios.delete(`${common.path.base_url}/investmentDoc`, {
            headers: { "Authorization": token },
            data: {
              id: file._id,
              userId: userId,
            },
          })
            //@TODO CALL ON SUCCESS OF API
            .then((response) => {
              this.setState({ loading: false });
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
              }).then(() => {
                this.loadDashboardData();
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              if (
                error.response &&
                error.response.data &&
                error.response.data.message &&
                common.AUTH_EXPIRED === error.response.data.message
              ) {
                window.localStorage.clear();
                window.location.href = "/";
                return;
              } else {
                swal({
                  title: "Error! #48512",
                  text:
                    error.response &&
                      error.response.data &&
                      error.response.data.message
                      ? error.response.data.message
                      : "Something Went Wrong",
                  icon: "error",
                  button: "Ok!",
                });
              }
            }
            )
            //@TODO CALL ON SUCCESS OF API
            .then((response) => {
              this.setState({ loading: false });
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: "Ok!",
              })
                .then(() => {
                  this.loadDashboardData();
                });
            })
            .catch((error) => {
              this.setState({ loading: false });
              if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
              } else {
                swal({
                  title: "Error! #48512",
                  text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                  icon: "error",
                  button: "Ok!",
                });
              }
            });
        }
        else {
          swal({ text: `${file.name} file is safe!`, icon: 'info' });
        }
      });
  }
  loadDashboardData = () => {
    const url = new URL(window.location.href);
    this.investmentId = url.searchParams.get('investmentId');
    this.userId = window.localStorage.getItem('id');
    if (this.investorId) {
      this.userId = this.investorId;
    }
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/myInvestmentsById/${this.userId}/${this.investmentId}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.length > 0) {
          this.setState({ investmentDetailData: response.data.data[0] })
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error! #455",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });

  }
  iconRedirect = (data, type) => {
    const investment = data.investment;
    switch (type) {
      case "IIR":
        //window.open(`/iirPreviewPDF?companyId=${investment.companyId}&investmentId=${investment._id}`, '_blank')
        window.open(
          `/previewIIR?companyId=${investment.companyId}&investmentId=${investment._id}`,
          "_self"
        );
        break;
      case "EL":
        window.open(
          `/foundLetterPreview?companyId=${investment.companyId}&investmentId=${investment._id}`,
          "_self"
        );
        break;
      case "FAQ":
        window.open(`/investorFaqPreview`, "_self");
        break;
      default:
        break;
    }
  };

  webinarPopup = (userId, investmentId) => {
    window.location.href =
      "/webinar-request?investor=" + userId + "&investment=" + investmentId;
  }


  render() {
    const { windowSize } = this.props;
    return (
      <>
        <UploadDocumentModal
          onRef={(ref) => this.uploadDocumentModalChild = ref}
          onUploadInvestorDocClick={() => this.loadDashboardData()}
        />
        <TButton
          onClick={() => { }}
          id="uploadInvestorDocument-btn"
          dataTarget={`#uploadInvestorDocModal`}
          dataToggle={`modal`}
          style={{ display: "none" }}
        />
        <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12">
          <div className="card my-custom-card">
            <div className="item_box">
              <div className="series">
                {this.state.investmentDetailData &&
                  this.state.investmentDetailData.company && (
                    <img
                      src={getInvestmentLogoFromS3(
                        this.state.investmentDetailData &&
                        this.state.investmentDetailData.company
                      )}
                      alt=""
                    />
                  )}

                {this.state.investmentDetailData &&
                  this.state.investmentDetailData.company && (
                    <p>{this.state.investmentDetailData.investment.name}</p>
                  )}
                {/* {this.state.investmentDetailData &&
                  this.state.investmentDetailData.investment &&
                  this.state.investmentDetailData.investment.stage ===
                  "closed" ? (
                  <button className="btn-success dark-bg">CLOSED</button>
                ) : (
                  <button className="btn-success">COMPLETE</button>
                )} */}
              </div>
              <div className="my_prices">
                <h4>
                  {this.state.investmentDetailData &&
                    getUserInvestmentAmount(
                      this.state.investmentDetailData._id,
                      this.state.investmentDetailData.investment.userInvestments
                    ) ? (
                    <NumberFormat
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={getUserInvestmentAmount(
                        this.state.investmentDetailData._id,
                        this.state.investmentDetailData.investment
                          .userInvestments
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  ) : (
                    "N/A"
                  )}
                </h4>
                <p style={{ marginBottom: "10px !important" }}>My Investment</p>
                {!windowSize ? <table className="desktop_view">
                  <tbody>
                    <tr>
                      <td className="content-left">
                        <span>
                          {this.state.investmentDetailData &&
                            mapDateFormat(
                              this.state.investmentDetailData.investment
                                .closedDate
                            )}
                        </span>
                        <p>Close Date</p>
                      </td>
                      <td>
                        <span className="valuation-secondary">
                          {this.state.investmentDetailData &&
                            this.state.investmentDetailData.investment &&
                            this.state.investmentDetailData.investment
                              .shareType &&
                            this.state.investmentDetailData.investment
                              .shareType === "Common" &&
                            "~ "}
                          <NumberFormat
                            value={
                              this.state.investmentDetailData &&
                              companyValutionFormat(
                                this.state.investmentDetailData.investment
                                  .companyValuation
                              )
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </span>
                        <p>Valuation</p>
                      </td>
                      <td className="content-right">
                        <span>
                          <NumberFormat
                            fixedDecimalScale={true}
                            value={
                              this.state.investmentDetailData &&
                              this.state.investmentDetailData.investment
                                .pricePerShare
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </span>
                        <p>PPS</p>
                      </td>
                    </tr>
                  </tbody>
                </table> :
                  <table className="mobile_view">
                    <tbody>
                      <tr>
                        <td>
                          <p>Close Date</p>
                        </td>
                        <td>
                          {" "}
                          <span>
                            {this.state.investmentDetailData &&
                              mapDateFormat(
                                this.state.investmentDetailData.investment
                                  .closedDate
                              )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Valuation</p>
                        </td>
                        <td>
                          <span className="valuation-secondary">
                            {this.state.investmentDetailData &&
                              this.state.investmentDetailData.investment &&
                              this.state.investmentDetailData.investment
                                .shareType &&
                              this.state.investmentDetailData.investment
                                .shareType === "Common" &&
                              "~ "}
                            <NumberFormat
                              fixedDecimalScale={true}
                              value={
                                this.state.investmentDetailData &&
                                this.state.investmentDetailData.investment
                                  .companyValuation
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>PPS</p>
                        </td>
                        <td>
                          <span>
                            <NumberFormat
                              value={
                                this.state.investmentDetailData &&
                                this.state.investmentDetailData.investment
                                  .pricePerShare
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>}
              </div>
              <div className='d-flex justify-between icons-alignment col-12'>
                <div onClick={() => { this.iconRedirect(this.state.investmentDetailData, 'IIR') }}
                  style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <span
                    title="IIR"
                    className="view-span-icon-iir"
                    style={{ margin: "0px 10px" }}
                  >
                    <img
                      src={
                        path.report
                      }
                      alt=""
                      className="report"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <b>&nbsp; IIR</b>
                </div>
                <div onClick={() => { this.iconRedirect(this.state.investmentDetailData, 'EL') }}
                  style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <span
                    title="Executive Letter"

                    className="view-span-icon-ex"
                    style={{ margin: "0px 10px" }}

                  >
                    <img
                      src={
                        path.latter
                      }
                      alt=""
                      className="latter"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <b>&nbsp; Executive Letter</b>
                </div>
                <div onClick={() => { this.iconRedirect(this.state.investmentDetailData, 'FAQ') }}
                  style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <span
                    title="FAQs"
                    className="view-span-icon-faq"
                    style={{ margin: "0px 10px" }}
                  >
                    <img
                      src={
                        path.question
                      }
                      alt=""
                      className="question"
                      style={{ width: "20px" }}
                    />
                  </span>
                  <b>&nbsp; FAQs</b>
                </div>
                {this.state.investmentDetailData &&
                  ((this.state.investmentDetailData.investment.webinarLink1 != null &&
                    this.state.investmentDetailData.investment.webinarLink1.link != null) ||
                    (this.state.investmentDetailData.investment.webinarLink2 != null &&
                      this.state.investmentDetailData.investment.webinarLink2.link)) &&

                  (
                    <div onClick={() =>
                      this.webinarPopup(
                        this.state.investmentDetailData._id,
                        this.state.investmentDetailData.investment._id
                      )
                    }
                      style={{ display: "flex", padding: "10px 0px", alignItems: "center", cursor: "pointer" }}>
                      <span
                        title="Webinar"
                        style={{ marginRight: "10px" }}
                      >
                        <img
                          src={path.white_webinar_link}
                          alt=""
                          style={{ width: "40px" }}
                        />
                      </span>
                      <b>&nbsp; WEBINAR</b>
                    </div>
                  )
                }
              </div>
              {
                this.state.investmentDetailData &&
                <Documents
                  onClick={() => { this.setState(this.uploadDocumentModalChild.setInvestmentData(this.state.investmentDetailData.investment, () => this.upload())) }}
                  deleteInvestmentDoc={(docFile) => this.deleteInvestmentDoc(docFile)}
                  investmentDetailData={this.state.investmentDetailData}
                  signOA={this.state.signOA}
                  {...this.props}
                />
              }

            </div>
          </div>
        </ div>
      </>
    );
  }
}
export default withWindowSize(DetailView);
